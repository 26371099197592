//
// Name:            Navigation
//
// Description:     Contains all navigation styles
//

// ========================================================================
// Component: Navigation
// ========================================================================


.topnav {
	display:block;
	position:absolute;
	top:75px;
	left:0;
	width:100%;
	height:calc(100vh - 75px);
	background:#000;
	z-index:999;
	overflow:hidden;
	transition:.6s;
	transform:translate3d(0, -120%, 0);

	ul {
		position:absolute;
		z-index:9999;
		list-style:none;
		padding:0;
		position:relative;
		top:50%;
		transform:translate(0, -50%);
		transition:.6s;

		li {
			display:inline-block;
			width:100%;
			margin:10px 0;

			a {
				display:inline-block;
				width:100%;
				text-align:center;
				color: #ffffff;
				font-family: $dastreets;
				font-size: 40px;
				line-height:32px;
				font-weight: 400;
				letter-spacing: 2px;

				&:hover {
					color:$orange;
				}
			}
		}
	}
	.menu-diamond {
		position:absolute;
		top:0;
		left:75%;
		width:300px;
		height:500px;
		background:url(../images/menu-diamond.png) 50% no-repeat;
		background-size:100%;
		transform:translate3d(100%, 0, 0);
		opacity:0;
		transition:.6s;

		&.on {
			opacity:1;
			transform:translate3d(-50%, 0, 0);
		}
	}
	.menu-triangle {
		position:absolute;
		bottom:0;
		left:0;
		width:200px;
		height:300px;
		background:url(../images/menu-triangle.png) left bottom no-repeat;
		background-size:100%;
		opacity:0;
		transform:translate3d(0, 100%, 0);
		transition:.6s;
		transition-delay:.3s;

		&.on {
			opacity:1;
			transform:translate3d(0, 0, 0);
		}
	}
	.menu-triangle1 {
		position:absolute;
		top:38%;
		left:-100px;
		width:200px;
		height:300px;
		background:url(../images/menu-triangle1.png) 50% no-repeat;
		background-size:100%;
		opacity:0;
		transform:translate3d(-100%, -50%, 0);
		transition:.6s;
		transition-delay:.6s;

		&.on {
			opacity:1;
			transform:translate3d(0, -50%, 0);
		}
	}
	.menu-triangle2 {
		position:absolute;
		top:0;
		left:0;
		width:200px;
		height:300px;
		background:url(../images/menu-triangle2.png) left top no-repeat;
		background-size:100%;
		opacity:0;
		transform:translate3d(0, -100%, 0);
		transition:.6s;
		transition-delay:.9s;

		&.on {
			opacity:1;
			transform:translate3d(0, 0, 0);
		}
	}
}

.hamburger + .topnav {
	transform:translate3d(0, -120%, 0);
}

.hamburger.is-active + .topnav {
	transform:translate3d(0, 0, 0);
}


@media only screen and (max-width: 9999px) and (min-width: 1023px) {
	.topnav,
	.hamburger + .topnav {
		right:0;
		left:inherit;
		width:50%;
		transform: translate3d(100%,-120%,0);
	}
	.hamburger.is-active+.topnav {
		transform: translate3d(100%,0,0);
	}
}
