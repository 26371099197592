//
// Name:            Responsive
//
// Description:     Contains all style definitions concerning basic
//					structure and layout
//

// ========================================================================
// Component: Responsive Page
// ========================================================================


@media only screen and (max-width: 9999px) and (min-width: 1025px) {
	#drink .snap {
		display:none;
	}
	#drink .recommended {
		height:calc(100vh - 75px);
	}
}

@media only screen and (max-width: 9999px) and (min-width: 1023px) {
	.step sup {
		font-size:50%;
		line-height:40px;
		vertical-align:top;
	}
	#drink .about-slide .about .white-box p.title {
		color:#1c1c1c;
	}
	#drink .about-slide .about .white-box {
		padding:40px 60px;
	}
	#drink .swiper-container .swiper-slide .ingredients {
		margin-top:100px !important;
	}
	#drink {
		background:transparent;
	}
	#drink .about-slide {
		right:0;
		top:0;
		width:50%;
	}
	#drink .swiper-container .swiper-slide .about .white-box p {
		text-align:center;
	}
	#drink .swiper-container .swiper-slide .about,
	#drink .swiper-container .swiper-slide .ingredients {
		margin:0 auto;
		max-width:700px;
	}
	#drink .about-slide .bg,
	#drink .swiper-container .step-slide .bg {
		width:50%;
		position:relative;
		left:inherit;
		display:inline-block;
	}
	#drink .swiper-container .step-slide .step {
		width:50%;
		left:inherit;
		right:0;
		top:0;
		bottom:0;
		display:inline-block;
		height:100%;
		background:#fff;

		&:before {
			position:absolute;
			content:'';
			left:0;
			top:0;
			width:100%;
			height:100%;
			background:url(../images/grit.png) top left no-repeat;
			background-size:cover;		
		}
	}
	#drink .swiper-container .swiper-slide .step .num {
		left:0;
		top:50%;
	}
	#drink .swiper-container .swiper-slide .step p {
		color:#1c1c1c;
		position:absolute;
		top:50%;
		left:50%;
		transform:translate(-50%, -50%);
		margin:0;
		width:100%;
		padding:0 80px;
		font-size:42px;
		line-height:50px;
	}
	#drink .swiper-container .swiper-slide .step p.on {
		left:50%;
	}
	#drink .swiper-container .swiper-slide .step .num {
		font-size:92px;
	}
	#drink .about-slide .bg,
	#drink .swiper-container .swiper-slide .bg {
		width:50%;
	}
	#drink .swiper-container .swiper-slide .cnt {
		position:absolute;
		width:50%;
		height:100vh;
		right:0;
		top:0;
		background:#fff;
		z-index:999;

		&:before {
			position:absolute;
			content:'';
			left:0;
			top:0;
			width:100%;
			height:100%;
			background:url(../images/grit.png) top left no-repeat;
			background-size:cover;		
		}
	}
	#drink .about-slide .overlay,
	#drink .swiper-container .swiper-slide .overlay {
		width:50%;
	}
	#drink .about-slide .about .white-box p,
	#drink .swiper-container .swiper-slide .ingredients ul li {
		color:#1c1c1c;
		font-size:24px;
		line-height:30px;
		text-align:left;
	}
	#drink .about-slide .about .white-box p.heading,
	#drink .swiper-container .swiper-slide .ingredients p {
		//text-align:left;
		font-size:48px;
		margin:0 0 20px 0;
	}
	#drink .swiper-container .swiper-slide .ingredients ul li:before {
		top:6px;
	}
	#drink .progress .overlay {
		background-size:20%;
	}
	#drink .swiper-container .swiper-slide .snap p.heading,
	#drink .swiper-container .swiper-slide .recommended p.heading {
		font-size:36px;
	}
	#drink .swiper-container .swiper-slide .snap h2,
	#drink .swiper-container .swiper-slide .recommended h2 {
		font-size:100px;
		line-height:72px;
	}
	#drink .about-slide .bg-wrapper,
	#drink .swiper-container .swiper-slide .bg-wrapper {
		width:50%;
	}
	#drink .about-slide .bg-wrapper .bg,
	#drink .swiper-container .swiper-slide .bg-wrapper .bg {
		width:auto;
	}
	#drink .about-slide .bg-wrapper .bg.on,
	#drink .swiper-container .swiper-slide .bg.on {
		transform: translate(-25%,-50%) scale(1);
	}
	#drink .swiper-container .swiper-slide:first-child .bg.on {
		transform: translate(-50%,-50%) scale(1);
	}
	#drink .about-slide .about .white-box p,
	#drink .swiper-container .swiper-slide:first-child .ingredients ul li {
		font-size:14px;
		line-height:20px;
	}
	#drink .about-slide .about .white-box p.heading,
	#drink .swiper-container .swiper-slide .ingredients p {
		font-size:24px;
	}
	#drink .snap p.heading,
	#drink .recommended p.heading {
		font-size:18px;
	}
	#drink .snap h2,
	#drink .recommended h2 {
		font-size:92px;
		line-height:58px;
	}
	#default .wrapper {
		max-width:1280px;
		display:block;
	}
	#default h1 {
		font-size:80px;
		line-height:80px;
		padding:40px 0 20px 0;
		letter-spacing:2px;
	}
}

@media only screen and (max-width: 9999px) and (min-width: 1900px) {
	#drink .swiper-container .swiper-slide .bg.on {
		transform: translate(0,-50%) scale(1)!important;
	}
}

@media only screen and (max-width: 9999px) and (min-width: 480px) {
	#lucky-drink .cnt .drink-pic {
		height:300px;
	}
}

@media only screen and (max-width: 9999px) and (min-width: 769px) {
	#intro .down-triangle {
		width:350px;
		height:300px;
		background:url(../images/down-triangle-desktop.png) 50% no-repeat;

		.title {
			font-size: 36px;
			margin-top:30px;			
		}
		p {
			font-size: 24px;
			letter-spacing: 3px;
			line-height: 30px;
			padding:0 60px;			
		}
	}
	#intro .up-triangle {
		width:350px;
		height:300px;
		background:url(../images/up-triangle-desktop.png) 50% no-repeat;

		.title {
			font-size: 36px;
			margin-top:145px;
		}
		p {
			font-size: 24px;
			letter-spacing: 3px;
			line-height: 30px;			
			padding:0 80px;			
		}
	}
	// #intro .down-triangle.left {
	// 	top:280px;
	// 	left:40px;
	// }
	// #intro .down-triangle.right {
	// 	top:280px;
	// 	right:40px;
	// }
	// #intro .up-triangle {
	// 	top:300px;
	// }
	// #intro .paint-btn {
	// 	margin:0;
	// 	top:650px;
	// 	margin-top:0;
	// 	font-size: 20px;
	// }
	#intro .down-triangle.left,
	#intro .down-triangle.right {
		top:350px;
	}
	#intro .up-triangle {
		top:370px;
	}
	#intro .paint-btn {
		top:750px;
		font-size:20px;
	}
	#intro .paint-btn i {
		right:20px;
	}
}

@media only screen and (max-width: 9999px) and (min-width: 1024px) {
	#intro h1 {
		font-size: 120px !important;
		line-height: 99px !important;
	}
	#intro .heading {
		font-size: 64px !important;
	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	#explore-filtering #cities .next, #explore-filtering #cities .prev,
	#explore-filtering #glassware .next, #explore-filtering #glassware .prev {
		display:none;
	}
	#default .step {
		padding-bottom:40px;
	}
	#drink-bg4,
	#drink-bg7,
	#drink-bg9,
	#drink-bg13,
	#drink-bg17 {
		top:30%;
	}
	#drink-bg16 {
		top:40%;
	}
}

@media only screen and (max-width: 768px) and (min-width: 580px) {
	#age-check .book-subtitle {
		height:100px;
		max-width:350px;
	}
	#age-check .book-title {
		height:150px;
		max-width:420px;
		margin:-50px auto 0 auto;
	}
	#age-check .graphic {
		width:250px;
		height:250px;
	}
	#age-check .cnt {
		margin:20px auto;
	}
	#age-check .cnt .heading {
		font-size:18px;
	}
	#age-check .cnt p {
		font-size:14px;
		line-height:18px;
	}
	#age-check .form-footer input[type=submit] {
		width:160px;
		height:70px;
		background-size:100%;
	}
	#intro h1 {
		font-size:72px;
		line-height:60px;
	}
	#intro .heading {
		//font-size:38px;
		//line-height:42px;
		max-width:500px;
		margin:50px auto 20px auto;
		display:block;
	}
	#intro .welcome {
		display:block;
		font-size:16px;
		line-height:20px;
		color:#000;
		height:150px;
		max-width:600px;
		padding:50px 80px;
		margin:0 auto;
		background:url(../images/welcome.svg) top center no-repeat;
		background-size:auto 100%;
	}
	#intro .triangle {
		left:-20px;
		background-size:100%;
	}
	#intro .diamond-video {
		width:750px;
		height:1000px;
		left:60% !important;
		transform: translate3d(100%,-100px,0);
	}
	#intro .diamond-video.on {
		transform: translate3d(-50%,-100px,0);
	}
	#intro #landing-video {
		width:2000px;
	}
	#intro .pattern {
		top:450px;
		left:0;
		left:-110px;
	}
	#intro h1 br {
		display:none;
	}
	.recipe-section .make-btn:after,
	.recipe-section .make-btn:before,
	#featured .make-btn:before,
	#top .make-btn:before,
	#featured .make-btn:after,
	#top .make-btn:after {
		background-size:200%;
	}
	#explore .texture {
		background-size:50%;
	}
	#explore-filtering #filter .drink-item {
		height:210px;
	}
	#explore-filtering #featured {
		height:270px;
	}
	.topnav .menu-diamond {
		width:500px;
		height:900px;
	}
	.topnav .menu-triangle2 {
		width:300px;
		height:400px;
	}
	#intro .down-triangle.left {
		left:120px;
	}
	#intro .down-triangle.right {
		right:120px;
	}
	#intro .paint-btn {
		top:600px;
	}
}

@media only screen and (max-width: 580px) and (min-width: 1px) {
	#explore .map-box {
		height:auto;
	}
	#explore .map-box .map {
		width:100%;
		top:0;
		left:0;
		position:relative;
		transform: translate3d(0,0,0);
	}
	#explore .map-box .map div {
		position:relative !important;
		top:inherit !important;
		left:inherit !important;
		right:inherit;
		width:100%;
		margin:10px 0;
		text-align:center !important;
	}
	#explore .map-box .map .map-item {
		font-size:20px;

		a br {
			display:none;
		}
	}
	#explore .map-box .map .map-item:before {
		display:none;
	}
	#intro .down-triangle.left {
		left:60px;
		top:350px;
	}
	#intro .down-triangle.right {
		right:60px;
		top:350px;
	}
	#intro .up-triangle {
		top:370px;
	}
	#intro .diamond-video {
		top:-100px;
		left:100px;
	}
	#intro .paint-btn {
		top:600px;
	}
	#intro .pattern {
		background-position:5px top;
	}
}

@media only screen and (max-width: 420px) and (min-width: 1px) {
	#default h1 {
		font-size:32px;
	}
	#intro .down-triangle.left {
		left:20px;
		top:300px;
	}
	#intro .down-triangle.right {
		right:20px;
		top:300px;
	}
	#intro .up-triangle {
		top:320px;
	}
	#intro .diamond-video {
		top:-50px;
		left:50px;
	}
	#intro .paint-btn {
		top:500px;
	}
}

@media only screen and (max-width: 420px) and (min-width: 1px) {
	#intro .paint-btn {
		top:600px;
	}
	#intro .down-triangle.left,
	#intro .down-triangle.right {
		top:380px;
	}
	#intro .up-triangle {
		top:400px;
	}
	#intro .diamond-video {
		left:80px;
		top:-80px;
	}
	#intro .pattern {
		left:-220px;
		top:400px;
	}
}

@media only screen and (max-width: 340px) and (min-width: 1px) {
	#intro .diamond-video {
		top:-20px;
		left:20px;
	}
	#intro .pattern2 {
		right:50px;
	}
	#intro .down-triangle.left {
		left:20px;
		top:300px;
	}
	#intro .down-triangle.right {
		right:20px;
		top:300px;
	}
	#intro .up-triangle {
		top:350px;
	}
	#intro .paint-btn {
		top:500px;
	}
}

@media only screen and (max-width: 1024px) and (min-width: 480px) {
	#age-check .background2 {
		background:url(../images/tbg2.png) top left no-repeat;
	}
	#age-check .background3 {
		background:url(../images/tbg3.png) top left no-repeat;
	}
	#age-check .background4 {
		background:url(../images/tbg4.png) top left no-repeat;
	}
}

@media only screen and (max-width: 9999px) and (min-width: 769px) {
	#voice-box {
		display:none !important;
	}
	#drink {
		height:auto;
	}
	#drink .snap {
		display:none;
	}
	#drink .recommended {
		height:100vh;
	}
	#age-check .book-subtitle {
		height:100px;
		max-width:85%;
	}
	#age-check .book-title {
		height:150px;
		max-width:85%;
		margin:-50px auto 0 auto;
		background-position:top left;
	}
	#age-check .graphic {
		position:absolute;
		top:100px;
		right:45px;
		width:300px;
		height:300px;
	}
	#age-check .graphic .drink1,
	#age-check .graphic .drink2,
	#age-check .graphic .drink3 {
		width:300px;
		height:300px;
	}
	#age-check .logo {
		width:240px;
		height:80px;
	}
	#age-check .top-border-left,
	#age-check .top-border-right {
		width:calc(50% - 140px);
		height:26px;
	}
	#age-check .top-border-left:after,
	#age-check .top-border-left:before,
	#age-check .top-border-right:after,
	#age-check .top-border-right:before,
	#age-check .bottom-border:after,
	#age-check .bottom-border:before {
		height:25px;
		width:25px;
	}
	#age-check .bottom-border {
		height:26px;
	}
	#age-check .left-border,
	#age-check .right-border {
		width:24px;
	}
	#age-check .cnt {
		width:60%;
		text-align:left;
		padding-left:70px;
		margin:20px 0;
	}
	#age-check #bday {
		margin:40px 0 80px 0;
		width:100%;
		max-width:100%;
		padding-left:70px;

		.month {
			width:60px;
		}
		.day {
			width:60px;
		}
		.year {
			width:80px;
		}
		.form-list {
			width:250px;
		}
		.form-footer {
			width:auto;
		}
		.form-footer {
			display:inline-block;
			width:auto;
			margin:0 0 0 40px;
			padding:0;
			vertical-align:top;
		}
		.form-list {
			display:inline-block;
			margin:0;
			padding:0;
			vertical-align:top;
		}		
	}
	#age-check .cnt .heading,
	#age-check .cnt p {
		text-align:left;
		padding:0;
	}
	#age-check .cnt .heading {
		font-size:20px;
		line-height:24px;
	}
	#age-check .cnt p {
		font-size:16px;
		line-height:20px;
	}
	#age-check .form-footer input[type=submit] {
		width:160px;
		height:75px;
		background-size:100%;
		margin:0;
		background-position:top center;
	}
	#age-check #bday .form-list li {
		margin:20px 2% 0 2%;
	}
	#featured .recent-box .heading,
	#featured .top-box .heading,
	#top .recent-box .heading,
	#top .top-box .heading {
		font-size:24px;
	}
	#featured .recent-box h2,
	#featured .top-box h2,
	#top .recent-box h2,
	#top .top-box h2 {
		font-size:100px;
		line-height:100px;
	}
	.recipe-section .wrapper .recipe-box h1 {
		font-size:100px;
		line-height:80px;
	}
	.recipe-section .make-btn:after,
	.recipe-section .make-btn:before,
	#featured .make-btn:before,
	#top .make-btn:before,
	#featured .make-btn:after,
	#top .make-btn:after {
		background-size:200%;
	}
	#explore .texture {
		background-size:50%;
	}
	#explore .map-box .map {
		display:block;
		margin:20px auto;
		width:700px;
		height:450px;
		background:url(../images/full-map.png) top left no-repeat;
		background-size:100%;
	}
	#explore-filtering #filter .drink-item {
		height:300px;
	}
	#explore-filtering #featured {
		height:350px;
	}
	#events {
		background:url(../images/event-bg.png) 50% no-repeat;
		background-size:cover !important;
	}
	#events .event-box {
		background:url(../images/large-event.svg) 50% no-repeat;
		max-width:750px;
		background-size:100%;
		padding:40px 80px;
		margin:0 auto;
	}
	#events .event-box .meta {
		margin-top:30px;
	}
	#events .event-box h2 a {
		font-size:40px;
	}
	#events .event-box .excerpt {
		font-size:18px;
		line-height:24px;
		margin-top:0;
	}
	#events .event-box .cnt {
		width:100%;
		padding:0 80px;
	}
	#events .events-diamond {
		display:block;
		position:absolute;
		z-index:9999;
		top:500px;
		left:-250px;
		width:468px;
		height:933px;
		background:url(../images/events-diamond1.png) 50% no-repeat;
	}
	#events .orange-triangle {
		display:block;
		position:absolute;
		z-index:9999;
		top:400px;
		left:100px;
		width:200px;
		height:200px;
		background:url(../images/orange-triangle.png) 50% no-repeat;
	}
	#events .events-triangle1 {
		display:block;
		position:absolute;
		z-index:9999;
		bottom:350px;
		right:-215px;
		width:432px;
		height:432px;
		background:url(../images/events-triangle1.png) 50% no-repeat;
	}
	#events .events-triangle2 {
		display:block;
		position:absolute;
		z-index:9999;
		top:0;
		left:-100px;
		width:461px;
		height:461px;
		background:url(../images/events-triangle2.png) 50% no-repeat;
	}
	#events .events-triangle3 {
		display:block;
		position:absolute;
		z-index:9999;
		top:0;
		right:-400px;
		width:995px;
		height:995px;
		background:url(../images/events-triangle3.png) 50% no-repeat;
	}
	#events .events-triangle4 {
		display:block;
		position:absolute;
		z-index:9999;
		bottom:318px;
		left:50px;
		width:681px;
		height:681px;
		background:url(../images/events-triangle4.png) 50% no-repeat;
	}	
	.map .chicago {
		top:165px;
		left:432px;
	}
	.map .galvelston {
		top:400px;
		left:310px;
	}
	.map .tampa {
		top:398px;
		right:120px;
	}
	.map .seattle {
		top:25px;
		left:25px;
	}
	.map .sandiego {
		top:289px;
		left:43px;
	}
	.map .lasvegas {
		top:200px;
		left:90px;
	}
	.map .portland {
		top:59px;
		left:10px;
	}
	.map .neworleans {
		top:369px;
		left:383px;
	}
	.map .denver {
		top:173px;
		left:209px;
	}
	.map .newjersey {
		top:166px;
		right:43px;
	}
	.map .kansascity {
		top:173px;
		left:309px;
	}
	.map .detroit {
		top:154px;
		right:153px;
	}
	.map .charlotte {
		top:249px;
		right:71px;
	}
	.map .phoenix {
		top:285px;
		left:126px;
	}
	#explore-filtering #cities,
	#explore-filtering #cities .city-box,
	#explore-filtering #difficulty,
	#explore-filtering #difficulty .skill-box,
	#explore-filtering #glassware,
	#explore-filtering #glassware .glass-box {
		height:180px;
	}
	#explore-filtering #filter .drink-item .cnt h2,
	#explore-filtering #filter .drink-item .cnt h2 .small {
		font-size:42px;
		line-height:40px;
	}
	#explore-filtering #featured .cnt h2 {
		font-size:72px;
		line-height:70px;
	}
	#explore-filtering #featured .cnt .heading {
		font-size:24px;
	}
	.topnav .menu-diamond {
		width:600px;
		height:900px;
	}
	.topnav .menu-triangle2 {
		width:300px;
		height:400px;
	}
	.topnav .menu-triangle {
		width:300px;
		height:400px;
	}
	.recipe-section .wrapper .recipe-box .small {
		font-size:46px;
		width:auto;
		display:inline;
	}
	.recipe-section .info p.glass span,
	.recipe-section .info p,
	#drink .mobile-bar a {
		font-size:14px;
	}
	.recipe-section .info p.glass span br {
		display:none;
	}
	.next-start,
	#drink .next, #drink .next-end {
		width:58px !important;
		height:92px !important;
	}
	.next-start .arrow,
	#drink .next-end .arrow, #drink .next .arrow {
		top:27px !important;
		left:28px !important;
	}
	.prev-start,
	#drink .prev {
		width:46px !important;
		height:92px !important;
	}
	.prev-start .arrow,
	#drink .prev .arrow {
		top:28px !important;
	}
	#drink .swiper-container .swiper-slide .about .white-box {
		padding:40px 60px;
	}
	#drink .swiper-container .swiper-slide .ingredients {
		padding:0 60px;
	}
	#intro h1 {
		max-width:1080px;

		br {
			display:none;
		}
	}
}

@media only screen and (max-width: 9999px) and (min-width: 768px) {
	#intro .heading {
		font-size:38px;
		line-height:42px;
		max-width:500px;
		margin:40px auto 20px auto;
		display:block;
	}
	#intro .welcome {
		display:block;
		font-size:16px;
		line-height:20px;
		color:#000;
		height:150px;
		max-width:600px;
		padding:50px 80px;
		margin:0 auto;
		background:url(../images/welcome.svg) top center no-repeat;
		background-size:auto 100%;
	}
}

@media only screen and (max-width: 1023px) and (min-width: 769px) {
	#intro .diamond-video {
		width:824px;
		height:1350px;
		left:60% !important;
		transform: translate3d(-50%,-300px,0);
	}
	#intro .diamond-video.on {
		transform: translate3d(-50%,-400px,0);
	}
	#intro #landing-video {
		width:3000px;
	}
	#intro .texture {
		background-size:cover !important;
	}
	#intro .triangle {
		left:-25px;
	}
	#intro .pattern {
		background-size:100% !important;
	}
	#intro h1 {
		font-size:72px;
		line-height:70px;
		max-width:700px;
		margin:0 auto;
		display:block;
	}
	#intro .heading {
		font-size:38px;
		line-height:42px;
	}
	#intro .welcome {
		display:block;
		font-size:16px;
		line-height:20px;
		color:#000;
		height:150px;
		max-width:700px;
		padding:50px 80px;
		margin:0 auto;
		background:url(../images/welcome.svg) top center no-repeat;
		background-size:auto 100%;
	}
	#intro .down-triangle.left,
	#intro .down-triangle.right {
		top:350px;
	}
	#intro .up-triangle {
		top:370px;
	}
	#intro .paint-btn {
		top:750px;
	}
	#intro .paint-btn i {
		right:20px;
	}
}

@media only screen and (max-width: 9999px) and (min-width: 1024px) {
	#intro .diamond-video {
		width:824px;
		height:1350px;
		left:60% !important;
		transform: translate3d(100%,-300px,0);
	}
	#intro .diamond-video.on {
		transform: translate3d(-50%,-400px,0);
	}
	#intro #landing-video {
		width:3000px;
	}
	#intro .texture {
		background-size:100%;
	}
	#intro .triangle {
		left:-25px;
		display:none;
	}
	// #intro .pattern {
	// 	background-size:200% !important;
	// 	right:-160px !important;
	// 	left:inherit !important;
	// 	top:300px !important;
	// 	width:500px !important;
	// }
	#intro .pattern2 {
		display:block;
	}
	#intro h1 {
		font-size:72px;
		line-height:70px;
		max-width:1200px;
		margin:0 auto;
		display:block;
	}
	#intro .heading {
		font-size:38px;
		line-height:42px;
	}
	#intro .welcome {
		display:block;
		font-size:16px;
		line-height:20px;
		color:#000;
		height:150px;
		max-width:600px;
		padding:50px 80px;
		margin:0 auto;
		background:url(../images/welcome.svg) top center no-repeat;
		background-size:auto 100%;
	}
	#intro .paint-btn {
		margin-top:-50px;
	}
	#intro .desktop-triangle {
		display:block;
		position:absolute;
		bottom:-90px;
		right:-175px;
		width:637px;
		height:704px;
		z-index:2;
		background-position:0 0;
		background-repeat:no-repeat;
		opacity:0;
		transform:translate3d(-100%, 0, 0) rotate(131deg);
		transition:.6s;

		&.on {
			transform:translate3d(0, 0, 0) rotate(131deg);
			opacity:1;
		}
	}
	#intro .desktop-triangle2 {
		display:block;
		position:absolute;
		top:-30px;
		left:-20px;
		width:637px;
		height:504px;
		z-index:2;
		background-position:0 0;
		background-repeat:no-repeat;
		opacity:0;
		transform:translate3d(-100%, 0, 0) rotate(4deg);
		transition:.6s;

		&.on {
			transform:translate3d(0, 0, 0) rotate(4deg);
			opacity:1;
		}
	}	
	.topnav .menu-diamond {
		width:400px;
		height:600px;
	}
	#age-check .book-subtitle {
		height:120px;
		margin:100px auto 0 auto;
	}
	#age-check .book-title {
		height:200px;
		margin:-70px auto 0 auto;
	}
	#age-check .cnt {
		padding-left:100px;
		width:50%;
	}
	#age-check .graphic {
		width:450px;
		height:450px;
	}
	#age-check .graphic .drink1,
	#age-check .graphic .drink2,
	#age-check .graphic .drink3 {
		width:450px;
		height:450px;
	}
	#age-check #bday {
		padding-left:100px;
	}
	.recipe-section .make-btn:after,
	.recipe-section .make-btn:before,
	#featured .make-btn:before,
	#top .make-btn:before,
	#featured .make-btn:after,
	#top .make-btn:after {
		background-size:100%;
	}
	#explore .texture {
		display:none;
	}
	#age-check .background2 {
		background:url(../images/dbg1.png) top left no-repeat;
	}
	#age-check .background3 {
		background:url(../images/dbg2.png) top left no-repeat;
	}
	#age-check .background4 {
		background:url(../images/dbg3.png) top left no-repeat;
	}
	#explore-filtering #featured {
		display:none;
	}
	#explore-filtering #filter {
		border-top:20px solid #fff;
	}
	#explore-filtering #cities .city-box .cnt {
		font-size:42px;
	}
	#explore-filtering #filter .drink-item .cnt h2,
	#explore-filtering #filter .drink-item .cnt h2 .small {
		font-size:52px;
		line-height:50px;
	}
	.recipe-section .wrapper .recipe-box h1 {
		max-width:960px;
	}
	.recipe-section .wrapper .recipe-box h1,
	.recipe-section .wrapper .recipe-box h1 a,
	.recipe-section .wrapper .recipe-box .small {
		font-size:120px;
		line-height:100px;
	}
}

@media only screen and (max-width: 980px) and (min-width: 880px) {
	#age-check .graphic {
		width:350px;
		height:350px;
	}
}

@media only screen and (max-width: 1023px) and (min-width: 981px) {
	#age-check .graphic {
		width:450px;
		height:450px;
	}
	#age-check .cnt {
		width:50%;
	}
}

@media only screen and (max-width: 9999px) and (min-width: 1200px) {
	#age-check .graphic {
		right:100px;
		width:500px;
		height:500px;
	}
	#intro .down-triangle.left {
		left:150px !important;
		top:330px;
	}
	#intro .down-triangle.right {
		right:150px !important;
		top:330px;
	}
	#intro .up-triangle {
		top:350px;
	}
	#intro .paint-btn {
		top:95vh;
	}
	#intro .desktop-triangle {
		bottom:-390px;
	}
}

@media only screen and (max-width: 1379px) and (min-width: 1080px) {
	#intro .pattern2 {
		top:-400px;
		right:60px;
		width:750px;
		height:750px;
	}
	#intro .pattern {
		width:750px;
		left:-220px;
		top:380px;
	}
	#intro .desktop-triangle,
	#intro .desktop-triangle.on {
		right:-350px;
		transform:translate(0, 0, 0) rotate(122deg) ;
	}
}

@media only screen and (max-width: 1079px) and (min-width: 1024px) {
	#intro .pattern2 {
		top:-400px;
		right:60px;
		width:750px;
		height:750px;
	}
	#intro .pattern {
		width:750px;
		left:-220px;
		top:380px;
	}
	#intro .desktop-triangle,
	#intro .desktop-triangle.on {
		right:-350px;
		transform:translate(0, 0, 0) rotate(122deg) ;
	}
}

@media only screen and (max-width: 9999px) and (min-width: 1380px) {
	#intro .down-triangle.left {
		left:200px !important;
	}
	#intro .down-triangle.right {
		right:200px !important;
	}
	#intro .pattern2 {
		top:-400px;
		right:60px;
		width:750px;
		height:750px;
	}
	#intro .pattern {
		width:750px;
		left:-220px;
		top:380px;
	}
	#intro .desktop-triangle,
	#intro .desktop-triangle.on {
		right:-350px;
		transform:translate(0, 0, 0) rotate(122deg) ;
	}
}

@media only screen and (max-width: 9999px) and (min-width: 1080px) {
	#intro .down-triangle.left {
		left:100px;
	}
	#intro .down-triangle.right {
		right:100px;
	}
	#intro h1 {
		margin:60px auto 0 auto;
	}
}
