//
// Name:            Footer
//
// Description:     Contains all footer styles
//

// ========================================================================
// Component: Footer
// ========================================================================


footer {
	background-color: #1c1c1c;

	.wrapper {
		overflow:hidden;
	}
	.social {
		list-style:none;
		padding:0;
		text-align:center;
		margin-top:95px;

		li {
			display:inline-block;
			width:30px;
			height:30px;
			margin:0 10px;

			a {
				display:inline-block;
				width:100%;
				height:30px;
				text-indent:-9999px;
			}
			&.facebook {
				background:url(../images/facebook.png) 50% no-repeat;
			}
			&.twitter {
				background:url(../images/twitter.png) 50% no-repeat;
			}
			&.instagram {
				background:url(../images/instagram.png) 50% no-repeat;
			}
		}
	}
	.footer-nav {
		list-style:none;
		padding:0;
		text-align:center;
		margin:20px 0;

		li {
			display:inline-block;
			width:auto;
			margin:0 5px;

			a {
				font-size:12px;
				line-height:18px;
				font-weight:400;
				text-decoration:underline;
				font-family:$gillreg;
				color:#fff;
			}
		}
	}
	.legal {
		text-align:center;
		padding:0 40px;

		p {
			font-size:12px;
			line-height:18px;
			color:#fff;
			font-family:$gillreg;
			margin:0;
			padding:0 0 10px 0;

			a {
				color:#fff;
			}
		}
	}
	.icon {
		display:block;
		margin:10px auto 20px auto;
		width:250px;
		height:50px;
		background:url(../images/DrinkSmart.svg) 50% no-repeat;
		background-size:100%;
	}
}
