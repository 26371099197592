//
// Name:            Header
//
// Description:     Contains all header styles
//

// ========================================================================
// Component: Header
// ========================================================================


header {
	position:fixed;
	width:100%;
	height:75px;
	top:0;
	left:0;
	z-index:999;
	transition:.6s;
	transform:translate3d(0, -100px, 0);

	&.on {
		transform:translate3d(0, 0, 0);
	}
	.bg {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:75px;
		z-index:9999;
		background:#1c1c1c;
		box-shadow: 0 10px 15px rgba(0, 0, 0, 0.5);
	}
	.logo {
		position:absolute;
		top:15px;
		left:50%;
		width:132px;
		height:45px;
		z-index:99999;
		transform:translate(-50%, 0);

		a {
			display:inline-block;
			width:132px;
			height:45px;
			text-indent:-9999px;
			background:url(../images/white-logo.svg) 50% no-repeat;
		}
	}
	.grid-btn {
		position:absolute;
		top:25px;
		left:25px;
		width:21px;
		height:21px;
		z-index:99999;

		&:before {
			position:absolute;
			content:'';
			width:8px;
			height:8px;
			background:$orange;
			bottom:0;
			left:0;
		}
		&:after {
			position:absolute;
			content:'';
			width:8px;
			height:8px;
			background:$orange;
			bottom:0;
			right:0;
		}
		span {
			display:block;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			text-indent:-9999px;

			&:before {
				position:absolute;
				content:'';
				width:8px;
				height:8px;
				background:$orange;
				top:0;
				left:0;
			}
			&:after {
				position:absolute;
				content:'';
				width:8px;
				height:8px;
				background:$orange;
				top:0;
				right:0;
			}
		}
	}
	.hamburger {
		display:block;
		position:absolute;
		top:21px;
		right:20px;
		padding:0;
		top:21px;
		z-index:99999;
	}
	.hamburger-inner,
	.hamburger-inner:after,
	.hamburger-inner:before {
		background-color:$orange;
		border-radius:0;
		height:3px;
		width:35px;
	}
}
