//
// Name:            Base
//
// Description:     Contains all base styles, including basic resets
//

// ========================================================================
// Component
// ========================================================================


body {
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
	font-size: 18px;
	line-height: 26px;
	background:#000;
}

a {
	@include transition(.6s);
}

.IIV::-webkit-media-controls-play-button,
video::-webkit-media-controls-start-playback-button,
video::-webkit-media-controls {
	display: none !important;
}

h1, h2, h3, h4 {
	font-weight: normal;
}
