//
// Name:            Photo Share
//
// Description:     Contains all style definitions concerning basic
//					structure and layout
//

// ========================================================================
// Component: Photo Share Page
// ========================================================================


#photo-share {

	form#filterForm {
		margin-top:75px;
		padding-bottom:75px;
		background:#fff;

		h2 {
			color: $orange;
			font-family: $daisywheel;
			font-size: 14px;
			letter-spacing: 1px;
			text-transform: uppercase;
		}
		.share-buttons {
			border-bottom:1px solid #ccc;
			font-size:0;
			height:50px;
		}
		.file-upload {
			display:inline-block;
			position:relative;
			z-index:1;
			width:50%;
			background:$orange;
			color:#fff;
			font-family: $daisywheel;
			font-size: 14px;
			letter-spacing: 1px;
			text-transform: uppercase;
			text-align:center;
			height:50px;
			line-height:50px;
			border:none;
		}
		#camera {
			position:absolute;
			top:0;
			left:0;
			z-index:9;
			opacity:0;
			width:50%;
			background:$orange;
			color:#fff;
			font-family: $daisywheel;
			font-size: 14px;
			letter-spacing: 1px;
			text-transform: uppercase;
			text-align:center;
			height:50px;
			cursor:pointer;
		}
		.done {
			display:inline-block;
			width:50%;
			text-align:center;
			color: #151515;
			font-family: $daisywheel;
			font-size: 14px;
			letter-spacing: 1px;
			text-transform: uppercase;
			background:transparent;
			border:none;
			height:50px;
			line-height:50px;
			cursor:pointer;
			padding:0;
		}
		#image-preview {
			display:block;
			position:relative;
			max-width:320px;
			width:92%;
			height:320px;
			border:5px solid $orange;
			margin:20px auto;
			overflow:hidden;

			img {
				max-width:100%;
				width:100%;
				position:absolute;
				top:50%;
				left:0;
				transform:translate(0, -50%);
			}
		}
		.apply-filter1 {

			.filter {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				z-index:9;
				background:url(../images/Filter_01.png) 50% no-repeat;
				background-size:100% 100%;
			}
		}
		.apply-filter2 { //Only two filters - SM

			.filter {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				z-index:9;
				background:url(../images/Filter_05.png) 50% no-repeat;
				background-size:100% 100%;
			}
		}
		#filter-select {
			text-align:center;
			font-size:0;
			margin:10px auto;
			width:92%;
			max-width:320px;
			text-align:center;

			label {
				display:inline-block;
				width:calc(33.3333% - 10px);
				height:80px;
				margin:5px;
				overflow:hidden;

				input {
					position:absolute;
					z-index:99;
					top:0;
					left:0;
					width:100%;
					height:100%;
					background:transparent;
					border-radius:0;
					border:2.5px solid #ccc;
					-webkit-appearance:none;
					cursor:pointer;

					&:checked {
						border:2.5px solid $orange;
					}
				}
				img {
					position:absolute;
					z-index:1;
					width:100%;
					height:auto;
					top:50%;
					left:0;
					transform:translate(0, -50%);
				}
			}
			#filter1 {

				.filter {
					position:absolute;
					top:0;
					left:0;
					width:100%;
					height:100%;
					z-index:9;
					background:url(../images/Filter_01.png) 50% no-repeat;
					background-size:100% 100%;
				}
			}
			#filter2 { //Only using two filters now - SM

				.filter {
					position:absolute;
					top:0;
					left:0;
					width:100%;
					height:100%;
					z-index:9;
					background:url(../images/Filter_05.png) 50% no-repeat;
					background-size:100% 100%;
				}
			}
		}
	}
}

.loader {
	display:none;
	position:absolute;
	top:50%;
	left:50%;
	width:60px;
	height:60px;
	z-index:999999999;
	background:url(../images/loader.gif) 50% no-repeat;
	background-size:100%;
	transform:translate(-50%, -50%);
}

#ingredient-share {
	display:none;
	position:absolute;
	top:50%;
	left:50%;
	z-index:99999999999;
	box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
	background-color: #1c1c1c;
	width:92%;
	max-width:650px;
	height:auto;
	transform:translate(-50%, -50%);

	h2 {
		color: #ff4200;
		font-family: $daisywheel;
		font-size: 14px;
		font-weight: 400;
		letter-spacing: 1px;
		text-transform: uppercase;
		text-align:center;
		padding:10px 20px;
		margin:0;
		border-bottom:1px solid #4a4a4a;
	}
	.fb-send-to-messenger {
		display:block;
		margin:10px auto;
		height:auto !important;
		overflow:hidden;
		position:relative !important;
		width:100% !important;
		display:block !important;
		margin:0 auto !important;
		max-width:180px !important;
		padding:10px 0 !important;

		> span {
			height:40px !important;
		}
		iframe {
			position:relative !important;
			width:100% !important;
			height:40px !important;
			display:block !important;
			margin:0 auto !important;
			max-width:180px !important;
		}
	}
	.fb_iframe_widget span {
		width:100% !important;
	}
	.close {
		display:inline-block;
		width:100%;
		color: #fff;
		font-family: $daisywheel;
		font-size: 14px;
		font-weight: 400;
		letter-spacing: 1px;
		text-transform: uppercase;
		text-align:center;
		padding:10px 0;
		margin:0;
		border-top:1px solid #4a4a4a;
	}
}
#photo-box {
	display:none;
	position:absolute;
	top:50%;
	left:50%;
	z-index:99999999999;
	box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
	background-color: #1c1c1c;
	width:92%;
	max-width:650px;
	height:auto;
	transform:translate(-50%, -50%);

	h2 {
		color: #ff4200;
		font-family: $daisywheel;
		font-size: 14px;
		font-weight: 400;
		letter-spacing: 1px;
		text-transform: uppercase;
		text-align:center;
		padding:10px 0;
		margin:0;
		border-bottom:1px solid #4a4a4a;
	}
	#downloads {
		text-align:center;
		font-size:0;
		padding:0;
		margin:0;
		list-style:none;

		li {
			display:inline-block;
			width:33.3333%;
			color: #ffffff;
			font-family: $daisywheel;
			font-size: 10px;
			line-height:14px;
			letter-spacing: 1px;
			text-align:center;
			padding:20px 0;
			border-bottom:1px solid #4a4a4a;

			&:first-child {
				border-right:1px solid #4a4a4a;
			}
			&:last-child {
				border-left:1px solid #4a4a4a;
			}

			a {
				color:#fff;
			}
		}
	}
	#share {

		.a2a_kit {
			font-size:0;
			text-align:center;

			.a2a_dd {
				display:none !important;
			}
			a {
				display:inline-block;
				width:16.6666%;
				height:50px;
				border-bottom:1px solid #4a4a4a;

				span {
					display:none !important;
				}
			}
			.a2a_button_facebook, .fb-ico {
				background:url(../images/share-facebook.svg) 50% no-repeat;
				border-right:1px solid #4a4a4a;
			}
			.a2a_button_twitter, .tw-ico {
				background:url(../images/share-twitter.svg) 50% no-repeat;
				border-right:1px solid #4a4a4a;
			}
			.a2a_button_pinterest, .pin-ico {
				background:url(../images/share-pinterest.svg) 50% no-repeat;
				border-right:1px solid #4a4a4a;
				background-size:25px 25px;
			}
			.a2a_button_sms {
				background:url(../images/share-chat.svg) 50% no-repeat;
				border-right:1px solid #4a4a4a;
				background-size:25px 25px;
			}
			.a2a_button_email {
				background:url(../images/share-mail.svg) 50% no-repeat;
				border-right:1px solid #4a4a4a;
				background-size:25px 25px;
			}
			.instagram {
				background:url(../images/share-instagram.svg) 50% no-repeat;
				border-right:1px solid #4a4a4a;
			}
		}
		.close {
			display:inline-block;
			width:100%;
			color: #fff;
			font-family: $daisywheel;
			font-size: 14px;
			font-weight: 400;
			letter-spacing: 1px;
			text-transform: uppercase;
			text-align:center;
			padding:10px 0;
			margin:0;
			border-top:1px solid #4a4a4a;
		}
	}
}
