//
// Name:            Forms
//
// Description:     Contains all form styles
//

// ========================================================================
// Component: Form
// ========================================================================

.form-wrapper {
	margin:20px auto;
	padding:40px 0;
	text-align:left;

	h1 {
		margin:0 0 20px 0;
	}
}

input,
select,
textarea {
	margin: 0;
	@include border-radius(0);
	font: inherit;
	color: inherit;

	// Focus
	&:focus {
		outline: 0;
	}
}

// Vertical alignment
input,
select {
	vertical-align: middle;
}

// Address inconsistent `.text-transform` inheritance which is only inherit in Firefox
select {
	text-transform: none;
}

optgroup {
	font: inherit;
	font-weight: bold;
}

input {

	// Removes inner padding and border in Firefox 4+.
	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
}

input {

	// Remove inner padding and search cancel button in Chrome, Safari and Opera on OS X.
	&[type="search"] {
		&::-webkit-search-cancel-button,
		&::-webkit-search-decoration {}
	}

	// Remove button on `.number` input
	&[type="number"] {
		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			appearance: none;
			margin: 0;
		}
	}
}

// Define consistent border, margin, and padding.
fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

textarea {
	overflow: auto;
	vertical-align: top;
	min-height: 150px;
}

// Removes placeholder transparency in Firefox.
::-moz-placeholder {
	opacity: 1;
}

// Removes `.box-shadow` for invalid controls in Firefox.
:invalid {
	box-shadow: none;
}

::-ms-clear {
	display: none;
}

// Style
// ========================================================================

// Remove margin from the last-child
form > {
	:last-child {
		margin-bottom: 0;
	}
}
&:-ms-input-placeholder {
	opacity:.5;
	color:#fff;
}
&::-moz-placeholder {
	opacity:.5;
	color:#fff;
}
&::-webkit-input-placeholder {
	opacity:.5;
	color:#fff;
}
&:disabled {
	&:-ms-input-placeholder {

	}
	&::-moz-placeholder {

	}
	&::-webkit-input-placeholder {

	}
}

// Controls
// Expand width to container of [class*="col-"]
select,
textarea,
input:not([type]),
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
	[class*="col-"] > & {
		width: 100%;
		display: block;
	}
}

input {
	&[type="checkbox"],
	&[type="radio"] {
		width: auto;
		display: inline-block;
	}
}

select,
textarea,
input {
	max-width: 100%;
	display: inline-block;
	transition: all linear 200ms;
	width: 100%;

	.form-error & {

	}
	.form-valid & {

	}

	// Focus state
	&:focus {

	}

	// Disabled state
	&:disabled {

	}
}

// Legend
legend {
	width: 100%;
	border: 0;
	padding: 0;

	&:after {
		content: "";
		display: block;
		width: 100%;
	}
}

// Reset height
// Must be after size modifiers
// ========================================================================

textarea,
select[multiple],
select[size] {
	height: auto;
}

// Sub-objects: `.label`
// ========================================================================

label {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	margin: 0;
}

.form {
	&-footer {
		* + & {

		}
	}
	&-error {
		label.error {
			font-size: 12px;
		}
	}
	&-success {
		label.success {
			font-size: 12px;
		}
	}
}

.gform_fields {

	&,
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}
	li {
		position: relative;
		margin-bottom:20px;
	}
	> li {
		display: flex;
		flex-direction: column;

		+ li {

		}
		> label {

		}
	}
}

input[type="text"],
input[type="email"],
input[type="password"] {
	background:$form-field-background;
	border:$form-field-border;
	height:$form-field-height;
	line-height:$form-field-height;
	font-size:$form-field-font-size;
	padding:$form-field-padding;
	border-radius:$form-field-border-radius;
	color:$form-field-color;

	&.error {

	}
	&:focus {
		outline: none;
		box-shadow: 0 0 4px #000;
	}
	&.valid {

	}
}

select {
	border:$form-field-border;
	height:$form-field-height;
}

.selectric {
	border:$form-field-border;
	border-radius:$form-field-border-radius;

	.label {
		font-size:$form-field-font-size;
		color:$form-field-color;
	}
}
.selectric-scroll li.highlighted {
	background:#f2f2f2;
}
.selectric-scroll li {
	margin:0;
}
.selectric-scroll li:hover {
	background:#f2f2f2;
}

textarea {
	border:$form-field-border;
	height:140px;
	padding:5px;
	color:$form-field-color;
	font-size:$form-field-font-size;
	border-radius:$form-field-border-radius;
}

input[type="submit"] {
	height:50px;
	line-height: 40px;
	padding:5px;
	cursor:pointer;
	width: 100%;
	background:#000;
	text-transform:uppercase;
	font-size:18px;
	border-radius:$form-field-border-radius;
	border:2px solid #000;

	&:hover {
		background:#fff;
	}
}

.ginput_container_fileupload {

	.button {

	}
	#extensions_message {
		padding-left:20px;
		font-size:11px;
		font-style:italic;
	}
	.inputfile {
		position: absolute;
		overflow: hidden;
		opacity: 0;
		padding: 0;
		margin: 0;
		top: 0;
		left: 0;
		z-index: 1;
		cursor: pointer;
		@include size(100%);

		&:hover {
			+ label {

			}
		}
		+ label {
			color: inherit;
			max-width: 100%;
			text-overflow: ellipsis;
			white-space: nowrap;
			display: inline-block;
			overflow: hidden;
			text-transform: none;
			display: inline-flex;
			align-items: center;
			transition: all linear 200ms;

			svg {
				width: 1em;
				height: 1em;
				vertical-align: middle;
			}
		}
	}
}

.gfield_checkbox {
	display: inline-block;
	position: relative;
	width:100%;

	li {
		position:relative;
		display:inline-block;
		width:auto;
	}
	label {
		line-height:20px;
		padding-left:30px;
		padding-right:30px;
	}
	label:before {
		position: absolute;
		content: '';
		transition: .3s cubic-bezier(.68,-.55,.265,1.55);
		top: 0;
		left: 5px;
		width: 20px;
		height: 20px;
		background:#fff;
		@include border-radius(2px);
		background-clip: padding-box;
		border:1px solid #ccc;
		text-align:center;
	}
	input:checked + label:before {
		content:'✔';
	}
	label.disabled {
		opacity: 0.75;
	}
	input {
		position: absolute;
		display: block;
		@include size(100%);
		top: 0;
		left: 0;
		z-index: 1;
		cursor: pointer;
		opacity: 0;
	}
}

.gfield_radio {
	display: inline-block;
	position: relative;
	width:100%;

	li {
		position:relative;
		display:inline-block;
		width:auto;
	}
	label {
		padding-left:30px;
		padding-right:30px;
		line-height:20px;

		&:before {
			top: 4px;
			right: 4px;
			top: 0;
			left: 4px;
			width:20px;
			height:20px;
			background:#fff;
			border-radius: 100%;
			position: absolute;
			content: '';
			border:1px solid #ccc;
			transition: .3s cubic-bezier(.68,-.55,.265,1.55);
		}
	}
	input:checked + label:before {

	}
	&.disabled {
		opacity: 0.75;
	}
	input {
		position: absolute;
		display: block;
		@include size(100%);
		top: 0;
		left: 0;
		z-index: 1;
		cursor: pointer;
		opacity: 0;
	}
}

.gform_footer {
	margin-top:20px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-justify-content: flex-end;
	justify-content: flex-end;
}
