//
// Name:            Events
//
// Description:     Contains all style definitions concerning basic
//					structure and layout
//

// ========================================================================
// Component: Events Page
// ========================================================================


#events {
	background:url(../images/events-bg.png) 0 0 repeat;

	.events-diamond,
	.orange-triangle,
	.events-triangle1,
	.events-triangle2,
	.events-triangle3,
	.events-triangle4 {
		display:none;
	}
	.wrapper {
		overflow:hidden;
	}
	h1 {
		margin:120px 0 20px 0;
		color: #ffffff;
		font-family: $daisywheel;
		font-size: 40px;
		font-weight: 400;
		letter-spacing: 1px;
		text-align:center;
		text-transform: uppercase;
	}
	.event-box {
		display:block;
		position:relative;
		padding:40px 60px;
		margin:0 auto 40px auto;
		height:270px;
		max-width:350px;
		z-index:9999;
		background:url(../images/events.svg) 50% no-repeat;

		.cnt {
			position:absolute;
			top:50%;
			left:50%;
			width:200px;
			transform:translate(-50%, -50%);
		}
		.meta {
			color: $orange;
			font-family: $daisywheel;
			font-size: 13px;
			font-weight: 400;
			text-transform: uppercase;
			margin:10px 0 5px 0;
		}
		h2 {
			margin:0;
			color: #1c1c1c;
			font-family: $dastreets;
			font-size: 36px;
			line-height:34px;
			font-weight: 400;
			letter-spacing: 1px;
			text-transform: uppercase;

			a {
				color: #1c1c1c;
				font-family: $dastreets;
				font-size: 36px;
				line-height:34px;
				font-weight: 400;
				letter-spacing: 1px;
				text-transform: uppercase;
			}
		}
		.excerpt {
			color: #1c1c1c;
			font-family: $gillreg;
			font-size: 14px;
			font-weight: 300;
			letter-spacing: 1px;
			line-height: 14px;
		}
		.more {
			position:relative;
			color: #151515;
			font-family: $daisywheel;
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 1px;
			text-transform: uppercase;
			padding-right:20px;

			.ico {
				position:absolute;
				top:0;
				right:0;
				width:10px;
				height:10px;
				background:url(../images/more.png) 50% no-repeat;
				transform:rotate(90deg);
			}
		}
	}
}
