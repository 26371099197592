//
// Name:            Home
//
// Description:     Contains all style definitions concerning basic
//					structure and layout
//

// ========================================================================
// Component: Home Page
// ========================================================================


@keyframes wave {
  0% {
    x: -400px;
  }
  100% {
    x: -5px;
  }
}
@-webkit-keyframes wave {
  0% {
    x: -400px;
  }
  100% {
    x: -5px;
  }
}
@keyframes fill-up {
  0% {
    height: 0;
    y: 55px;
  }
  100% {
    height: 140px;
    y: -65px;
  }
}
@-webkit-keyframes fill-up {
  0% {
    height: 0;
    y: 55px;
  }
  100% {
    height: 140px;
    y: -65px;
  }
}

.box {
	display:block;
	position:absolute;
	height:320px;
	width:200px;
	top:50%;
	left:50%;
	opacity:0;
	z-index:99999999999;
	background:url(../images/LoadingIconRed.svg) 0 -3px no-repeat;
	background-size:100%;
	transform:translate(-50%, -50%);

	p {
		position:absolute;
		top: 140px;
		left:0;
		font-size: 18px;
		font-weight: 400;
		line-height: 28px;
		color: #a9a9b0;
		padding:0 20px;
	}
}
svg {
	position:absolute;
	z-index:9;
	left:50%;
	overflow:visible;
	transform:translate(-50%, 0);
}

.loading {
	z-index:9999999;
}

.loader-box {

	.number-fill {
		animation: wave 0.8s 6 linear, fill-up 5.5s linear 1;
	}
}

.firefox .loader-box {
	background:url(../images/tail-spin.svg) 50% no-repeat;
	background-size:100%;
	width:120px;
	height:120px;

	svg {
		display:none;
	}
}

.ie .loader-box,
.edge .loader-box {
	background:url(../images/loader.gif) 50% no-repeat;
	background-size:100%;
	width:20px;
	height:20px;

	svg {
		display:none;
	}
}

.shake {
	color:#fff;
	font-size:42px;
	position:absolute;
	bottom:10px;
	left:10px;
	z-index:999999999999999999999999;

	p {
	color:#fff;
	font-size:42px;
	}
}

#iframePl {
	width:100%;
	height:calc(100vh - 75px);
	margin-top:75px;
}

#age-check {
	position:relative;
	height:auto;
	overflow:hidden;

	.logo {
		display:inline-block;
		position:absolute;
		left:50%;
		top:8px;
		width:160px;
		height:50px;
		z-index:9999;
		background:url(../images/age-logo.svg) 50% no-repeat;
		transform:translate(-50%, 0);
		background-size:100%;
	}
	.book-subtitle {
		display:block;
		position:relative;
		margin:70px auto 0 auto;
		width:100%;
		max-width:220px;
		height:60px;
		z-index:999;
		background:url(../images/Scotch.svg) top left no-repeat;
		background-size:auto 100% !important;	
	}
	.book-title {
		display:block;
		position:relative;
		margin:-35px auto 0 auto;
		width:100%;
		max-width:320px;
		height:87px;
		z-index:999;
		background:url(../images/Cocktails.svg) 50% no-repeat;		
		background-size:auto 100% !important;	
	}
	#bday {
		display:block;
		position:relative;
		z-index:999999;
		width:100%;
		max-width:420px;
		height:auto;
		margin:10px auto 80px auto;

		.form-list {
			list-style:none;
			padding:0 50px;
			margin:0;
			font-size:0;

			li {
				display:inline-block;
				width:29.3333%;
				margin:0 2%;

				&.remember {
					position:relative;

					label:before {
						position: absolute;
						content: '';
						transition: .3s cubic-bezier(.68,-.55,.265,1.55);
						top: 0;
						left: 5px;
						width: 20px;
						height: 20px;
						line-height:20px;
						background:#fff;
						color:$orange;
						@include border-radius(2px);
						background-clip: padding-box;
						border:1px solid #ccc;
						text-align:center;
					}
					input:checked + label:before {
						content:'✔';
					}
					label.disabled {
						opacity: 0.75;
					}
					input {
						position: absolute;
						display: block;
						@include size(100%);
						top: 0;
						left: 0;
						z-index: 1;
						cursor: pointer;
						opacity: 0;
					}
					label {
						display:inline-block;					
					    color: #1c1c1c;
					    text-align: left;
					    letter-spacing: 2px;
					    line-height: 14px;
					    font-size: 10px;
					    padding-left:30px;
					    text-transform:uppercase;
					    font-family:$avenir_medium;
					}
					p {
						display:inline-block;					
					    color: #1c1c1c;
					    text-align: left;
					    letter-spacing: 2px;
					    line-height: 12px;
					    font-size: 10px;
					    font-family:$avenir_medium;						

					    a {
					    	text-decoration:underline;
					    }
					}
				}
				label {
					display:none;
				}
				.selectric-wrapper {
					display:inline-block;
					width:100%;
					background:transparent;

					.selectric {
						background:transparent;
						border-top:none;
						border-left:none;
						border-right:none;
						border-bottom:2px solid #000;

						.label {
							color: #1c1c1c;
							font-family: $avenir_medium;
							font-size: 18px;
							font-weight: 500;
							line-height: 38px;
							margin:0;
							text-transform: uppercase;
						}
						.button {
							width:10px;
							height:38px;
							background:url(../images/more.png) 50% no-repeat;
							transform:rotate(180deg);
							box-shadow:none;
							border:none;
							font-family:$avenir_medium !important;

							&:after {
								border:none;
								box-shadow:none;
							}
						}
					}
					.selectric-items {

						.selectric-scroll li {
							width:100%;
							margin:0;
							color: #1c1c1c;
							font-family: $gillreg;
							font-size: 16px;
							font-weight: 500;
							line-height: 20px;
							text-transform: uppercase;
						}
						.selectric-scroll {

							&::-webkit-scrollbar {
								width:5px;
							}
							&::-webkit-scrollbar-track {
								background:transparent;
							}
							&::-webkit-scrollbar-thumb {
								background:$orange;
							}
						}				
					}
				}
			}
			.country {
				width:96% !important;
			}
			.remember {
				width:96% !important;
			}
		}
	}
	.form-footer {

		input[type="submit"] {
			display:block;
			width:120px;
			height:60px;
			background:url(../images/enter.svg) 50% no-repeat;
			border:none;
			box-shadow:none;
			margin:10px auto;
			text-indent:-9999px;
			background-size:100%;
		}
	}
	.curl-wrap {
		position:absolute;
		z-index:999;
		top:0;
		left:0;
	}
	.curl {
		width:120px;
		height:120px;
		position: absolute;
		top:0;
		left:0;
		background : 
			linear-gradient(
				135deg, 
				#fff, 
				#f3f3f3 45%, 
				#ddd 50%, 
				#aaa 50%, 
				#bbb 56%, 
				#ccc 62%, 
				#f3f3f3 80%,
				#fff 100%
			);
		box-shadow : 0 0 10px rgba(0, 0, 0, .5);
		transition: all .5s ease;
	}
	.curl:before,
	.curl:after {
		content: '';
		position: absolute;
		z-index: -1;
		left: 12.5%;
		bottom: 5.8%;
		width: 70%;
		max-width: 300px;
		max-height: 100px;
		height: 55%;
		box-shadow: 0 12px 15px rgba(0, 0, 0, .3);
		transform: skew(-10deg) rotate(-6deg);
	}
	.curl:after {
		left: auto;
		right: 5.8%;
		bottom: auto;
		top: 14.16%;
		transform: skew(-15deg) rotate(-84deg);
	}

	.curl:hover {
		width: 240px;
		height: 240px;
	}
	.curl:hover:before,
	.curl:hover:after {
		box-shadow: 0 24px 30px rgba(0, 0, 0, .3);
	}	
	.graphic {
		display:block;
		position:relative;
		z-index:99;
		margin:-20px auto 0 auto;
		width:200px;
		height:200px;
		z-index:99;

		.drink1 {
			opacity:0;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:200px;
			background:url(../images/age-drink1.png) 50% no-repeat;
			background-size:100%;
			transition:.6s;

			&.on {
				opacity:1;
			}
		}
		.drink2 {
			opacity:0;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:200px;
			background:url(../images/age-drink2.png) 50% no-repeat;
			background-size:100%;
			transition:.6s;

			&.on {
				opacity:1;
			}
		}
		.drink3 {
			opacity:0;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:200px;
			background:url(../images/age-drink3.png) 50% no-repeat;
			background-size:100%;
			transition:.6s;

			&.on {
				opacity:1;
			}
		}
	}
	.background1 {
		display:block;
		position:absolute;
		top:0;
		left:0;
		z-index:1;
		width:100%;
		height:100%;
		background:url(../images/bg2.png) 0 0 no-repeat;
		background-size:cover !important;
		opacity:0;

		&.on {
			z-index:9;
			opacity:1;
		}
	}	
	.background2 {
		display:block;
		position:absolute;
		top:0;
		left:0;
		z-index:1;
		width:100%;
		height:100vh;
		background:url(../images/bg2.png) 0 0 no-repeat;
		background-size:cover !important;
		opacity:0;

		&.on {
			z-index:9;
			opacity:1;
		}
	}
	.background3 {
		display:block;
		position:absolute;
		top:0;
		left:0;
		z-index:1;
		width:100%;
		height:100vh;
		background:url(../images/bg3.png) 0 0 no-repeat;
		background-size:cover !important;
		opacity:0;

		&.on {
			z-index:9;
			opacity:1;
		}
	}
	.background4 {
		display:block;
		position:absolute;
		top:0;
		left:0;
		z-index:1;
		width:100%;
		height:100vh;
		background:url(../images/bg4.png) 0 0 no-repeat;
		background-size:cover !important;
		opacity:0;

		&.on {
			z-index:9;
			opacity:1;
		}
	}
	.cnt {
		position:relative;
		width:100%;
		z-index:99;

		.heading {
			font-size:11px;
			text-transform:uppercase;
			color:#1c1c1c;
			text-align:center;
			padding:0;
			margin:0 0 10px 0;
			letter-spacing:2px;
			font-family:$avenir_bold;
		}
		p {
			font-size:10px;
			line-height:12px;
			color:#1c1c1c;
			text-align:center;
			padding:0 50px;
			margin:0;
			letter-spacing:2px;
			text-transform:uppercase;
			font-family:$avenir_regular;
		}
	}
	.top-border-left {
		position:absolute;
		top:20px;
		left:20px;
		width:calc(50% - 100px);
		height:16px;
		z-index:99;
		background:url(../images/Border_Top.svg) top right repeat-x;
		background-size:auto 100% !important;

		&:before {
			position:absolute;
			content:'';
			top:0;
			left:0;
			width:15px;
			height:15px;
			background:$orange;
			z-index:999;
		}
		&:after {
			position:absolute;
			content:'';
			top:0;
			right:0;
			width:10px;
			height:15px;
			background:$orange;
			z-index:999;
		}
	}
	.top-border-right {
		position:absolute;
		top:20px;
		right:20px;
		width:calc(50% - 100px);
		height:16px;
		z-index:99;
		background:url(../images/Border_Top.svg) top left repeat-x;
		background-size:auto 100% !important;

		&:before {
			position:absolute;
			content:'';
			top:0;
			right:0;
			width:15px;
			height:15px;
			background:$orange;
			z-index:999;
		}
		&:after {
			position:absolute;
			content:'';
			top:0;
			left:0;
			width:10px;
			height:15px;
			background:$orange;
			z-index:999;
		}
	}
	.left-border {
		position:absolute;
		top:20px;
		left:20px;
		bottom:20px;
		width:14px;
		z-index:99;
		background:url(../images/Border_Left.svg) top left repeat-y;
		background-size:100% !important;
	}
	.bottom-border {
		position:absolute;
		bottom:20px;
		left:20px;
		right:20px;
		height:16px;
		z-index:99;
		background:url(../images/Border_Bottom.svg) top left repeat-x;
		background-size:auto 100% !important;

		&:before {
			position:absolute;
			content:'';
			bottom:0;
			left:0;
			width:15px;
			height:15px;
			background:$orange;
			z-index:999;
		}
		&:after {
			position:absolute;
			content:'';
			bottom:0;
			right:0;
			width:15px;
			height:15px;
			background:$orange;
			z-index:999;
		}
	}
	.right-border {
		position:absolute;
		top:20px;
		right:20px;
		bottom:20px;
		width:14px;
		z-index:99;
		background:url(../images/right-border.png) top left repeat-y;
		background-size:100% !important;
	}
	.fade1 {
		opacity:.8;
	}
	.fade2 {
		opacity:.6;
	}
	.fade3 {
		opacity:.4;
	}
	.footer-links {
		position:relative;
		z-index:99999;
		padding:0 50px;
		margin-bottom:50px;

		ul {
			list-style:none;
			padding:0;

			li {
				display:inline-block;
				width:100%;
				margin:0 5px;
				line-height:14px;
				text-align:center;

				a {
					width:100%;
					text-align:center;
					font-family: $daisywheel;
					font-size: 12px;
					font-weight: 400;
					line-height: 14px;
					color:#000;
					text-align:center;
				}
			}
		}
	}
	.month-error, 
	.day-error,
	.year-error,
	.country-error,
	.check-error {
		display:none;
		width:100%;
		margin:0;
		font-size:11px;
		line-height:11px;
		color:$orange;
	}
}

#lucky .cnt {
	padding-bottom:100px !important;
}
#lucky-drink .cnt {
	padding-bottom:130px !important;
}
#lucky-drink {

	.close-x {
		position:absolute !important;
		top:5px !important;
		bottom:inherit !important;
		right:5px !important;
		width:20px !important;
		height:20px !important;
		background:url(../images/close.svg) 50% no-repeat;
		background-size:100%;
		z-index:9999;
	}	
}
#lucky, #shake-me, #lucky-drink {
	display:none;
	position:fixed;
	top:50%;
	left:50%;
	background:#1c1c1c;
	box-shadow:0 10px 10px rgba(0, 0, 0, 0.6);
	width:88%;
	max-width:650px;
	z-index:99999;
	transform:translate(-50%, -50%);

	.close-x {
		position:absolute !important;
		top:5px !important;
		bottom:inherit !important;
		right:5px !important;
		width:20px !important;
		height:20px !important;
		background:url(../images/close.svg) 50% no-repeat;
		background-size:100%;
		z-index:9999;
	}	
	.close {
		position:absolute;
		top:inherit !important;
		bottom:0;
		left:0;
		width:100%;
		height:30px !important;
		letter-spacing:2px;
		text-align:center;
		font-size:18px;
		line-height:30px;
		color:#fff;
		font-family:$daisywheel;
		text-transform:uppercase;
	}
	.another {
		position:absolute;
		top:inherit !important;
		bottom:0;
		left:0;
		width:100%;
		height:30px !important;
		letter-spacing:2px;
		text-align:center;
		font-size:18px;
		line-height:30px;
		color:#fff;
		font-family:$daisywheel;
		text-transform:uppercase;
	}
	.cnt {
		position:relative;
		padding:20px;

		h2 {
			font-family:$dastreets;
			font-size:46px;
			line-height:42px;
			color:#fff;
			letter-spacing:2px;
			text-align:center;
		}
		p {
			color: #ffffff;
			font-family: $daisywheel;
			font-size: 14px;
			font-weight: 400;
			letter-spacing: 3px;
			line-height: 20px;
			text-align:center;
			text-transform: uppercase;			
		}
		.icon {
			display:block;
			margin:10px auto 0 auto;
			width:100px;
			height:100px;
			background:url(../images/shake-icon.png) 50% no-repeat;
		}
		.drink-pic {
			display:block;
			margin:10px auto;
			max-width:200px;
			height:200px;
			width:100%;
			background-position:50%;
			background-repeat:no-repeat;
			background-size:cover !important;
		}
	}
	.lucky-make-btn {
		position:absolute;
		top:inherit !important;
		bottom:30px;
		left:0;
		width:100%;
		height:50px !important;
		background:$orange;
		letter-spacing:2px;
		text-align:center;
		font-size:18px;
		line-height:50px;
		color:#fff;
		z-index:99999;
		font-family:$daisywheel;
		text-transform:uppercase;

		&:before {
			position:absolute;
			content:'';
			top:0;
			left:0;
			width:20%;
			height:100%;
			background:url(../images/grunge.png) right center no-repeat;
			background-size:100%;
		}
		&:after {
			position:absolute;
			content:'';
			top:0;
			right:0;
			width:20%;
			height:100%;
			background:url(../images/grunge.png) left center no-repeat;
			background-size:100%;
		}
		.ico {
			position:absolute;
			top:50%;
			right:30px;
			width:10px;
			height:10px;
			background:url(../images/make-arrow.png) 50% no-repeat;
			transform:translate(0, -50%) rotate(90deg);
		}
	}
}

.page-overlay {
	display:none;
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:999;
	background:rgba(64, 64, 64, 0.9);
}

.left-side {
	position:absolute;
	top:0;
	left:0;
	width:100px;
	height:100%;
	background:url(../images/left-side.png) 0 0 repeat-y;
	z-index:99999;
}

.right-side {
	position:absolute;
	top:0;
	right:0;
	width:100px;
	height:100%;
	background:url(../images/right-side.png) right top repeat-y;
	z-index:99999;
}

#intro {
	overflow:hidden;
	position:relative;
	height:100vh;

	.wrapper {
		position:relative;
		z-index:999999;
	}
	.logo {
		display:block;
		margin:20px auto;
		width:132px;
		height:45px;
		background:url(../images/white-logo.svg) 50% no-repeat;
		background-size:100%;

		a {
			display:block;
			width:132px;
			height:45px;
			text-indent:-9999px;
		}
	}
	.heading {
		position:relative;
		text-shadow: 0 2px 24px rgba(0, 0, 0, 0.5);
		color: #ffffff;
		font-family: $dastreets;
		font-size: 24px;
		font-weight: 400;
		letter-spacing: 3px;
		line-height: 18px;
		text-align:center;
		margin:30px 0 20px 0;
		padding:0 20px;
		opacity:0;
		transform:translate3d(0, -100px, 0);
		transition:.7s;
		transition-delay:1.6s;

		&.on {
			transform:translate3d(0, 0, 0);
			opacity:1;
		}
	}
	h1 {
		color: #ffffff;
		font-family: $dastreets;
		font-size: 72px;
		font-weight: 400;
		letter-spacing: 3px;
		line-height: 60px;
		text-align:center;
		padding:0 20px;
		margin-top:40px;
		opacity:0;
		transform:translate3d(0, -60px, 0) scale(.2);
		transition:.7s;
		transition-delay:1.9s;

		&.on {
			transform:translate3d(0, 0, 0) scale(1);
			opacity:1;
		}
	}
	.welcome {
		color: #ffffff;
		font-family: $daisywheel;
		font-size: 14px;
		font-weight: 400;
		letter-spacing: 3px;
		line-height: 16px;
		text-transform: uppercase;
		text-align:center;
		padding:0 20px 10px 20px;
		opacity:0;
		transform:translate3d(0, 100px, 0);
		transition:.7s;
		transition-delay:2.2s;

		&.on {
			transform:translate3d(0, 0, 0);
			opacity:1;
		}
	}
	.ie-gif {
		display:none;
	}
	.down-triangle {
		display:block;
		position:absolute;
		background:url(../images/down-triangle.png) 50% no-repeat;
		width:152px;
		height:130px;
		top:300px;
		z-index:9;
		opacity:0;
		transition:1s;
		transition-delay:2.8s;

		&.on {
			opacity:1;
		}
		&.left {
			left:10px;
		}
		&.right {
			right:10px;
		}
		.title {
			display:inline-block;
			width:100%;
			color: #ffffff;
			font-family: $dastreets;
			font-size: 14px;
			text-align:center;
			letter-spacing: 2px;
			margin:10px 0 0 0;
		}
		p {
			margin:0;
			padding:0 23px;
			color: #ffffff;
			font-family: $daisywheel;
			font-size: 11px;
			line-height: 16px;
			text-transform: uppercase;
			letter-spacing: 1px;
			text-align:center;
		}
	}
	.up-triangle {
		display:block;
		position:absolute;
		background:url(../images/up-triangle.png) 50% no-repeat;
		width:152px;
		height:130px;
		top:320px;
		left:50%;
		z-index:9;
		opacity:0;
		transform:translate(-50%, 0);		
		transition:1s;
		transition-delay:2.8s;

		&.on {
			opacity:1;
		}
		.title {
			display:inline-block;
			width:100%;
			color: #ffffff;
			font-family: $dastreets;
			font-size: 14px;
			text-align:center;
			letter-spacing: 2px;
			margin:45px 0 0 0;
		}
		p {
			margin:0;
			padding:0 30px;
			color: #ffffff;
			font-family: $daisywheel;
			font-size: 11px;
			line-height: 16px;
			text-transform: uppercase;
			letter-spacing: 1px;
			text-align:center;
		}
	}
	.diamond-video {
	    display:block;
	    position:absolute;
	    top:0;
	    left:0;
	    width:350px;
	    height:600px;
	    z-index:999;	
		mask-image:url(../images/diamond.svg);
		mask-repeat:no-repeat;
		opacity:0;
		transform:translate3d(100%, 0, 0);
		transition:.6s;
		transition-delay:1.2s;

		&.on {
			transform:translate3d(40px, 0, 0);
			opacity:1;
		}
	}
	#landing-video {
	    display:block;
	    position:absolute;
	    top:0;
	    left:0;
	    margin:0;
	    padding:0;
	    top: 50%;
	    left: 50%;
	    min-height: 100%;
	    min-width: 100%;
	    width: 1060px;
	    height: 100%;
	    z-index:999;
	    opacity:1;
	    transform: translate(-50%,-50%) scale(1);
	}
	.overlay {
		display:none;
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:99999;
		background: -moz-linear-gradient(top,  rgba(0,0,0,1) 0%, rgba(0,0,0,0.05) 23%, rgba(0,0,0,0.01) 24%, rgba(0,0,0,0.01) 73%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.05) 23%,rgba(0,0,0,0.01) 24%,rgba(0,0,0,0.01) 73%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.05) 23%,rgba(0,0,0,0.01) 24%,rgba(0,0,0,0.01) 73%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	}
	.video-overlay {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:99999;
		background:#000;
		opacity:.5;
	}
	.bg {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:4;
		opacity:.8;
		background-position:20px -100px;
		background-repeat:no-repeat;
		background-size:115% !important;
	}
	.pattern {
		position:absolute;
		top:350px;
		left:-160px;
		width:100%;
		height:100%;
		z-index:3;
		background-position:50%;
		background-repeat:repeat;
		background-size:200% !important;
		mask-image:url(../images/diamond.svg);
		mask-repeat:no-repeat;
		opacity:0;
		transform:translate3d(-100%, 0, 0);
		transition:.8s;
		transition-delay:.4s;

		&.on {
			transform:translate3d(0, 0, 0);
			opacity:1;
		}
	}
	.pattern2 {
		display:block;
		position:absolute;
		top:-150px;
		right:100px;
		width:450px;
		height:450px;
		z-index:3;
		background-position:50%;
		background-repeat:repeat;
		background-size:200% !important;
		mask-image:url(../images/diamond.svg);
		mask-repeat:no-repeat;
		opacity:0;
		transform:translate3d(200%, 0, 0);
		transition:.8s;
		transition-delay:.4s;

		&.on {
			transform:translate3d(80%, 0, 0);
			opacity:1;
		}
	}
	.triangle {
		position:absolute;
		top:-30px;
		left:-95px;
		width:237px;
		height:404px;
		z-index:2;
		opacity:1;
		background-position:0 0;
		background-repeat:no-repeat;
		transform:rotate(3deg);
		opacity:0;
		transform:translate3d(-100%, 0, 0);
		transition:.6s;

		&.on {
			transform:translate3d(0, 0, 0);
			opacity:1;
		}
	}
	.desktop-triangle,
	.desktop-triangle2 {
		display:none;
	}
	.texture {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:1;
		opacity:1;
		background-position:0 0;
		background-repeat:no-repeat;
		background-size:cover !important;
	}
	@keyframes pulse {
		0% {transform: translate3d(-50%, 0, 0) scale(1);}
		50% {transform: translate3d(-50%, 0, 0) scale(1.1);}
		100% {transform: translate3d(-50%, 0, 0) scale(1);}
	}
	.paint-btn {
		animation-name: pulse;
		animation-duration: 1s;
		animation-duration: 2.5s;
		animation-fill-mode: both;
		animation-iteration-count: infinite;
		display:block;
		position:absolute;
		top:450px;
		left:50%;
		margin:10px auto 0 auto;
		width:177px;
		height:58px;
		color: #fff;
		font-family: $daisywheel;
		font-size: 14px;
		line-height:60px;
		text-indent:50px;
		font-weight: 400;
		letter-spacing: 1px;
		text-transform: uppercase;
		opacity:0;
		transform:translate3d(-50%, 100px, 0);
		transition:.7s;
		transition-delay:2.4s;

		i {
			display:block;
			position:absolute;
			top:20px;
			right:50px;
			font-size:18px;
		}
		&.on {
			transform:translate3d(-50%, 0, 0);
			opacity:1;
		}
	}
	.lucky-btn {
		text-indent:0;
		text-align:center;
	}
}

.edge, .ie {

	#intro {
		z-index:9;
	}
	#top {
		z-index:999;
		background:#000;
	}
	.diamond-video {

		.ie-diamond {
			width:100%;
			height:100%;
			opacity:1;
			z-index:99999;
			background:url(../images/ie-landing-image.png) 50% no-repeat;
			background-size:100%;
		}
		video, 
		.video-overlay {
			display:none !important;
		}
	}
	#intro .pattern {
		left:0;
		top:0;
		transform:translate3d(0, 0, 0) skew(-30deg) !important;
	}
	#intro .pattern2 {
		right:-200px;
		top:-250px;
		transform:translate3d(0, 0, 0) skew(-30deg) !important;
	}
	#intro .triangle {
		z-index:3;
	}
}

#top {
	z-index:99;
}

sup {
	font-family:$gillreg;
	font-size:50%;
	line-height:15px;
	vertical-align:top;
}

.recipe-section {
	overflow:hidden;

	.wrapper {
		position:relative;
		top:0;
		width:100%;
		z-index:3;
		text-align:center;
		height:100%;
		padding:95px 0 0 0;

		.recipe-box {
			position:relative;
			height:100%;

			.heading {
				color:$orange;
				font-family: $dastreets;
				font-size: 18px;
				font-weight: 400;
				letter-spacing: 1px;
				text-transform: uppercase;
				opacity:0;
				transition:.3s;
				transition-delay:.3s;

				&.on {
					opacity:1;
				}
			}
			h1 {
				display:block;
				position:relative;
				color: #ffffff;
				font-family: $dastreets;
				font-size: 46px;
				font-weight: 400;
				letter-spacing: 3px;
				line-height: 50px;
				text-align:center;
				padding:0 20px;
				max-width:700px;
				margin:0 auto;
				opacity:0;
				transition:.6s;
				transition-delay:1s;
				transform:scale(.2) translate3d(0, -60px, 0);

				a {
					color: #ffffff;
					font-family: $dastreets;
					font-size: 46px;
					font-weight: 400;
					letter-spacing: 3px;
					line-height: 50px;
					text-align:center;
				}
				&.on {
					opacity:1;
					transform:scale(1) translate3d(0, 0, 0);
				}
			}
			.small {
				display:block;
				font-size:34px;
				width:100%;
			}
			.meta {
				display:inline-block;
				position:absolute;
				bottom:20px;
				left:50%;
				overflow:hidden;
				width:280px;
				height:100px;
				color: #1c1c1c;
				font-family: $daisywheel;
				font-size: 15px;
				line-height:17px;
				font-weight: 400;
				letter-spacing: 1px;
				text-align:center;
				text-transform: uppercase;
				margin:0;
				padding:35px 0 0 0;
				background:url(../images/drink.svg) 50% no-repeat;
				background-size:100%;
				opacity:0;
				transition:.6s;
				transition-delay:1s;
				transform-origin:0 0;
				transform:scale(.2) translate3d(-50%, 60px, 0);

				&.brand {
					padding:25px 0 0 0;
				}
				&.on {
					opacity:1;
					transform:scale(1) translate3d(-50%, 0, 0);
				}
				.city,
				.crafted {
					display:block;
					width:100%;
					text-align:center;
				}
				.crafted {
					letter-spacing:0;

					span {
						display:block;
						width:100%;
						font-size:13px;
						line-height:14px;
					}
					a {
						color:#ff4200;
					}
				}
			}
		}
	}
	.info {
		display:inline-block;
		position:absolute;
		bottom:50px;
		vertical-align:top;
		width:100%;
		height:45px;
		z-index:999999;
		box-shadow: 0 -3px 4px rgba(0, 0, 0, 0.2);
		background-color: #1c1c1c;

		p {
			display:block;
			position:relative;
			float:left;
			width:50%;
			height:45px;
			color: #ffffff;
			font-family: $daisywheel;
			font-size: 11px;
			line-height:45px;
			font-weight: 400;
			letter-spacing: 1px;
			padding:0 15px;
			margin:0;
			text-transform: uppercase;
			vertical-align:top;
			text-align:center;

			span {
				display:inline-block;
				position:relative;
				padding-left:10px;
				line-height:15px;
				vertical-align:middle;
				text-align:left;

			}
			i {
				display:inline-block;
				position:relative;
				color:$orange;
				font-size:20px;
				vertical-align:middle;
			}
			&.glass {

				span {
					padding-left:0;
				}
				&:after {
					position:absolute;
					content:'';
					height:100%;
					width:1px;
					background:#444;
					top:0;
					right:0;
				}
				.highball {
					width:40px;
					height:40px;
					background:url(../images/Auchentoshan_Icons_Highball.svg) 50% no-repeat;
					background-size:100%;
				}
				.coupe {
					width:40px;
					height:40px;
					background:url(../images/Auchentoshan_Icons_Coupe.svg) 50% no-repeat;
					background-size:100%;
				}
				.footed {
					width:40px;
					height:40px;
					background:url(../images/Auchentoshan_Icons_Footed.svg) 50% no-repeat;
					background-size:100%;
				}
				.teacup {
					width:40px;
					height:40px;
					background:url(../images/Auchentoshan_Icons_TeaCup.svg) 50% no-repeat;
					background-size:100%;
				}
				.rocks {
					width:40px;
					height:40px;
					background:url(../images/Auchentoshan_Icons_Rocks.svg) 50% no-repeat;
					background-size:100%;
				}
				.tiki {
					width:40px;
					height:40px;
					background:url(../images/Auchentoshan_Icons_Snifter.svg) 50% no-repeat;
					background-size:100%;
				}
				.collins {
					width:40px;
					height:40px;
					background:url(../images/Auchentoshan_Icons_Collins.svg) 50% no-repeat;
					background-size:100%;
				}
				.pilsner {
					width:40px;
					height:40px;
					background:url(../images/Auchentoshan_Icons_Pilsner.svg) 50% no-repeat;
					background-size:100%;
				}
			}
			&.difficulty {

				&:after {
					position:absolute;
					content:'';
					height:100%;
					width:1px;
					background:#444;
					top:0;
					right:0;
				}
			}
		}		
	}
	.make-btn {
		position:absolute;
		bottom:0;
		width:100%;
		height:50px;
		display:inline-block;
		vertical-align:top;
		background:$orange;
		letter-spacing:2px;
		text-align:center;
		font-size:18px;
		line-height:50px;
		color:#fff;
		z-index:999999;
		font-family:$daisywheel;
		text-transform:uppercase;

		i {
			font-size:10px;
			vertical-align:top;
			margin-top:18px;
		}
		&:before {
			position:absolute;
			content:'';
			top:0;
			left:0;
			width:20%;
			height:100%;
			background:url(../images/grunge.png) right center no-repeat;
			background-size:400%;
		}
		&:after {
			position:absolute;
			content:'';
			top:0;
			right:0;
			width:20%;
			height:100%;
			background:url(../images/grunge.png) left center no-repeat;
			background-size:400%;
		}
		.ico {
			position:absolute;
			top:50%;
			right:30px;
			width:10px;
			height:10px;
			background:url(../images/make-arrow.png) 50% no-repeat;
			transform:translate(0, -50%) rotate(90deg);
		}
	}	
	.overlay {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:2;
		background: -moz-linear-gradient(top,  rgba(0,0,0,1) 0%, rgba(0,0,0,0.05) 23%, rgba(0,0,0,0.01) 24%, rgba(0,0,0,0.01) 73%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.05) 23%,rgba(0,0,0,0.01) 24%,rgba(0,0,0,0.01) 73%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.05) 23%,rgba(0,0,0,0.01) 24%,rgba(0,0,0,0.01) 73%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	}
	.bg-wrapper {
	    display:block;
	    position:absolute;
	    top:0;
	    left:0;
	    z-index:1;
	    width:100%;
	    height:calc(100% - 95px);
	    overflow:hidden;

	    img {
	    	
	    }
	}
	.bg {
	    display:block;
	    position:absolute;
	    top:0;
	    left:0;
	    margin:0;
	    padding:0;
	    top: 50%;
	    left: 50%;
	    min-height: 100%;
	    min-width: 100%;
	    width: auto;
	    height: auto;
	    z-index:1;
	    opacity:0;
	    transform: translate(-50%,-50%) scale(1.5);
		transition:1.2s;
		transition-delay:.6s;

		&.on {
			transform:translate(-50%,-50%) scale(1);
			opacity:1;
		}
	}
}

#explore {
	background:url(../images/explore-bg.jpg) #000 50% no-repeat;
	background-size:cover !important;

	.texture {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:3;
		opacity:1;
		background-size:100%;
		background-position:0 0;
		background-repeat:repeat;
	}
	.wrapper {
		position:relative;
		height:100%;
	}
	p {
		margin-top:75px;
		width:100%;
		color: #ffffff;
		font-family: $daisywheel;
		font-size: 30px;
		line-height:75px;
		font-weight: 400;
		letter-spacing: 1px;
		text-align:center;
		text-transform: uppercase;
	}	
	.map-box {
		position:relative;
		z-index:99;
		overflow:hidden;
		height:100vh;

		.map {
			position:absolute;
			width:600px;
			height:440px;
			top:85px;
			left:50%;
			background:url(../images/full-map.png) 50% no-repeat;
			background-size:100% !important;
			transform:translate3d(-300px, 0, 0);
			transition:1.2s;

			.map-item {
				position:absolute;
				color: #ffffff;
				font-family: $daisywheel;
				font-size: 14px;
				line-height:15px;
				font-weight: 400;
				letter-spacing: 1px;
				text-align:center;
				text-transform: uppercase;

				a {
					color:#fff;
				}
				&:before {
					position:absolute;
					top:-20px;
					left:50%;
					content:'';
					width:22px;
					height:20px;
					background:url(../images/map-triangle.png) 50% no-repeat;
					transform:translate(-50%, 0);
				}
			}
			.chicago {
				top:162px;
				left:430px;
			}
			.newyork {
				top:150px;
				left:605px;
			}
			.sanfrancisco {
				top:152px;
				left:-30px;
			}
			.galvelston {
				top:400px;
				left:310px;
			}
			.tampa {
				top:380px;
				right:120px;
			}
			.seattle {
				top:36px;
				left:23px;
			}
			.sandiego {
				top:275px;
				left:30px;
			}
			.lasvegas {
				top:240px;
				left:65px;
			}
			.portland {
				top:72px;
				left:-4px;
			}
			.neworleans {
				top:365px;
				left:416px;
			}
			.denver {
				top:190px;
				left:200px;
			}
			.newjersey {
				top:184px;
				right:42px;
			}
			.kansascity {
				top:195px;
				left:285px;
			}
			.detroit {
				top:144px;
				right:150px;
			}
			.charlotte {
				top:253px;
				right:45px;
			}
			.phoenix {
				top:285px;
				left:90px;
			}
		}
	}
	.pan {
		position:absolute;
		bottom:40px;
		right:20px;
		width:80px;
		height:30px;
		background:url(../images/pan.png) 50% no-repeat;
	}
}

#default {
	padding:60px 0;
	background:url(../images/texture-dark.jpg) 50% repeat;
	background-size:100% !important;

	.wrapper {
		padding:0 40px;
	}
	.photo {
		display:inline-block;
		position:relative;
		width:100%;
		height:300px;
		border:5px solid $orange;
		background-size:cover !important;

		p {
			position:absolute;
			bottom:-20px;
			left:0;
			text-align:center;
			text-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
			color: #ffffff;
			font-family:$edo;
			font-size: 100px;
			font-weight: 400;
			letter-spacing: 3px;
			opacity:1;
		}
	}
	.step {
		padding:40px 0 0 0;
		color: #ffffff;
		font-family:$gillreg;
		font-size: 20px;
		font-weight: 400;
		letter-spacing: 1px;
		line-height: 29px;
		text-align:center;
		opacity:1;
	}
	h1 {
		color:#fff;
		font-family:$dastreets;
		font-size:42px;
		line-height:40px;
		margin:40px 0 10px 0;
		width:100%;
		text-align:center;
		text-transform:uppercase;
	}
	h2 {
		color:#fff;
		font-family:$dastreets;
		font-size:20px;
		margin:40px 0 0 0;
		width:100%;
		text-transform:uppercase;
		text-align:left;
	}
	p {
		font-size:14px;
		line-height:18px;
		color:#fff;
		font-family:$gillreg;
		width:100%;
		opacity:.7;
		text-align:left;
	}
	ul {
		list-style:none;
		padding:0;
		width:100%;

		li {
			position:relative;
			font-size:14px;
			line-height:18px;
			color:#fff;
			font-family:$gillreg;
			padding-left:20px;
			margin:0 0 10px 0;
			opacity:.7;
			text-align:left;

			&:before {
				position:absolute;
				content:'';
				top:0;
				left:0;
				width:10px;
				height:10px;
				background:url(../images/down-arrow.png) 50% no-repeat;
			}
		}
	}
	ol {
		padding:0 10px 0 0;

		li {
			position:relative;
			font-size:14px;
			line-height:18px;
			color:#fff;
			font-family:$gillreg;
			padding-left:20px;
			margin:0 0 10px 0;
			opacity:.7;
			text-align:left;

			span {
				color:$orange;
				font-family:$daisywheel;
				text-transform:uppercase;
				width:100%;
				display:block;
				margin:20px 0 0 0;
				font-size:16px;
				font-weight:700;
				text-align:left;
			}
			sup {
				font-family:$gillreg;
			}
		}
	}
	.sitemap {

		li {
			text-align:left;

			a {
				font-weight:700;
			}
		}
	}	
	a {
		color:$orange;
	}
}

.gsa-bg {
	display:none;
}
.gsa {

	.topnav ul {
		top:40%;
	}
	.recipe-section .wrapper {
		height:calc(100% - 125px);
		padding:95px 0 0 0;
	}
	.recipe-section .wrapper .recipe-box .meta {
		position:relative;
		left:inherit;
		bottom:inherit;
		transform:translate(0, 0);
	}
	.recipe-section .info {
		position:relative;
		bottom:0;
	}
	.recipe-section .make-btn {
		position:relative;
	}
	video {
		display:none !important
	}
	.ie-diamond {
		display:block !important;
		width:100%;
		height:100%;
		opacity:1;
		z-index:99999;
		background:url(../images/ie-landing-image.png) 50% no-repeat;
		background-size:100%;
	}
	.gsa-bg {
		display:block;
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:1;
		background-size:cover !important;
	}
}

.desktop {

	.recipe-section .wrapper .recipe-box .meta {
		bottom:120px;
	}
}

.android {

	.recipe-section .wrapper .recipe-box .meta {
		bottom:160px !important;
	}
	.gsa-bg {
		display:block !important;
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:1;
		background-size:cover !important;
	}	
}