//
// Name:            Explore
//
// Description:     Contains all style definitions concerning basic
//					structure and layout
//

// ========================================================================
// Component: Explore Page
// ========================================================================


#explore-filtering {
	margin-top:75px;

	.wrapper {
		overflow:hidden;
	}
	#filters {
		display:inline-block;
		position:relative;
		width:100%;
		height:40px;
		background:#fff;
		vertical-align:top;

		a {
			display:inline-block;
			width:100%;
			color: #151515;
			font-family: $daisywheel;
			font-size: 14px;
			line-height:40px;
			font-weight: 400;
			letter-spacing: 1px;
			text-align:center;
			text-transform: uppercase;

			.ico {
				display:inline-block;
				position:relative;
				top:5px;
				width:20px;
				height:20px;
				background:url(../images/down-arrow.png) 50% no-repeat;
				transform:rotate(180deg);
				transition:.6s;
			}	
			&.open .ico {
				transform:rotate(0deg);
			}		
		}
		.dd-menu {
			display:none;
			position:absolute;
			top:40px;
			left:0;
			width:100%;
			height:auto;
			padding:20px 0;
			background:#fff;
			z-index:99999;
			border-top:1px solid #ccc;
		}
	}
	.filter-box {
		display:none;
	}
	#cities {
		display:inline-block;
		position:relative;
		width:100%;
		height:110px;
		vertical-align:top;

		.city-box {
			position:relative;
			width:100%;
			height:110px;
			background:#000;

			&:hover,
			&.active {

				.bg {
					opacity:1;
				}
			}
			a {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				z-index:9;
			}
			.cnt {
				position:absolute;
				top:50%;
				left:0;
				width:100%;
				height:auto;
				z-index:2;
				transform:tranlsate(0, -50%);
				color: #ffffff;
				font-family: $dastreets;
				font-size: 18px;
				font-weight: 400;
				text-align:center;
				letter-spacing: 1px;
				line-height: 16px;
				text-transform: uppercase;								
			}
			.bg {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				z-index:1;
				opacity:.6;
				background-position:50%;
				background-repeat:no-repeat;
				background-size:cover !important;
				transition:.6s;
			}
		}
		.sanfrancisco-box .bg {
			background-position:left center;
		}
		.prev {
			position:absolute;
			top:50%;
			left:10px;
			width:25px;
			height:30px;
			z-index:99;
			background:url(../images/RedArrow.svg) 50% no-repeat;
			transform:translate(0, -50%) rotate(180deg);
			background-size:100%;
		}
		.next {
			position:absolute;
			top:50%;
			right:10px;
			width:25px;
			height:30px;
			z-index:99;
			background:url(../images/RedArrow.svg) 50% no-repeat;
			transform:translate(0, -50%) rotate(0deg);
			background-size:100%;
		}
	}
	#difficulty {
		position:relative;
		width:100%;
		height:110px;
		vertical-align:top;

		.skill-box {
			position:relative;
			width:100%;
			height:110px;
			background:#000;

			&:hover,
			&.active {

				.bg {
					opacity:1;
				}
			}
			a {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				z-index:9;
			}
			.cnt {
				position:absolute;
				top:50%;
				left:0;
				width:100%;
				height:auto;
				z-index:2;
				transform:tranlsate(0, -50%);
				color: #ffffff;
				font-family: $dastreets;
				font-size: 18px;
				font-weight: 400;
				text-align:center;
				letter-spacing: 1px;
				line-height: 16px;
				text-transform: uppercase;								
			}
			.bg {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				z-index:1;
				opacity:.6;
				background-position:50%;
				background-repeat:no-repeat;
				background-size:cover !important;
				transition:.6s;
			}
		}
	}
	#glassware {
		position:relative;
		width:100%;
		height:110px;
		vertical-align:top;

		.glass-box {
			position:relative;
			width:100%;
			height:110px;
			background:#000;

			&:hover,
			&.active {

				.bg {
					opacity:1;
				}
			}
			a {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				z-index:9;
			}
			.cnt {
				position:absolute;
				top:50%;
				left:0;
				width:100%;
				height:auto;
				z-index:2;
				transform:translate(0, -50%);
				color: #ffffff;
				font-family: $dastreets;
				font-size: 18px;
				font-weight: 400;
				text-align:center;
				letter-spacing: 1px;
				line-height: 16px;
				text-transform: uppercase;								
			}
			.bg {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				z-index:1;
				opacity:.6;
				background-position:50%;
				background-repeat:no-repeat;
				background-size:cover !important;
				transition:.6s;
			}
		}
		.prev {
			position:absolute;
			top:50%;
			left:10px;
			width:30px;
			height:30px;
			z-index:99;
			background:url(../images/RedArrow.svg) 50% no-repeat;
			transform:translate(0, -50%) rotate(180deg);
			background-size:100%;
		}
		.next {
			position:absolute;
			top:50%;
			right:10px;
			width:30px;
			height:30px;
			z-index:99;
			background:url(../images/RedArrow.svg) 50% no-repeat;
			transform:translate(0, -50%) rotate(0deg);
			background-size:100%;
		}
	}
	#featured {
		display:inline-block;
		vertical-align:top;
		position:relative;
		width:100%;
		height:170px;
		margin-top:0;
		border-top:15px solid #fff;
		border-bottom:1px solid #fff;

		a {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			z-index:9;
		}
		.cnt {
			position:absolute;
			top:50%;
			left:0;
			width:100%;
			height:auto;
			z-index:2;
			transform:translate(0, -50%);

			.heading {
				color: #ffffff;
				font-family: $daisywheel;
				font-size: 14px;
				font-weight: 400;
				letter-spacing: 1px;
				text-transform: uppercase;
				text-align:center;
			}
			h2 {
				color: #ffffff;
				font-family: $dastreets;
				font-size: 30px;
				font-weight: 400;
				letter-spacing: 1px;
				text-align:center;
				margin:0;
			}
		}
		.bg {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			z-index:1;
			opacity:.6;
			background-position:50%;
			background-repeat:no-repeat;
			background-size:cover !important;	
		}
	}
	#filter {
		position:relative;
		overflow:hidden;
		background:#fff;

		.drink-item {
			width:33.3333%;
			position:relative;
			height:110px;
			border-bottom:1px solid #fff;
			background:#000;

			&:after {
				position:absolute;
				content:'';
				top:0;
				right:-1px;
				width:1px;
				height:100%;
				z-index:9;
				background:#fff;
			}
			a {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				z-index:9;
			}
			.cnt {
				position:absolute;
				top:50%;
				left:0;
				width:100%;
				height:auto;
				z-index:2;
				transform:translate(0, -50%);

				h2 {
					color: #ffffff;
					font-family: $dastreets;
					font-size: 18px;
					font-weight: 400;
					letter-spacing: 1px;
					line-height: 16px;
					text-align:center;
					padding:0 10px;

					.small {
						font-size:11px;
					}		
				}				
			}
			.bg {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				z-index:1;
				opacity:.6;
				background-position:50%;
				background-repeat:no-repeat;
				background-size:cover !important;	
			}
		}
	}
}
