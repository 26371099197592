//
// Component:       Variables
// Description:     Defines all color and style related values as variables
//                  to allow easy customization for the most common cases.
//
// ========================================================================

// ========================================================================
// Component: Variables
// ========================================================================


// Fonts
$daisywheel: 'daisywheelregular';
$dastreets: 'da_streetsregular';
$gillreg: 'GillSansMTStd-Light';
$edo: 'edoregular';
$avenir_medium:'AvenirNextMedium';
$avenir_regular:'AvenirNextRegular';
$avenir_bold:'AvenirNextBold';

// Colors
$orange:#ff4200;

// Forms
$form-field-border-radius:0;
$form-field-background:transparent;
$form-field-border:1px solid #979797;
$form-field-font-size:16px;
$form-field-color:#fff;
$form-field-padding:0 20px;
$form-field-height:44px;
