//
// Name:            Drink
//
// Description:     Contains all style definitions concerning basic
//					structure and layout
//

// ========================================================================
// Component: Drink Page
// ========================================================================

.make {
	display:none;
	position:fixed;
	top:75px;
	left:0;
	right:0;
	bottom:0;
	height:auto;
	padding:40px 30px 20px 30px;
	background:#000;
	z-index:99999;
	border:5px solid #ff4200;

	p {
		color: $orange;
		font-family: $dastreets;
		font-size: 24px;
		font-weight: 400;
		letter-spacing: 1px;
		text-align:center;
		margin:0 0 5px 0;
		text-transform: uppercase;						
	}
	.note {
		padding-top:20px;
		color:#fff;
		font-size:11px;
		line-height:13px;
		font-family: $daisywheel;
	}
	.extra {
		padding-top:40px;
		color: #fff;
		font-size: 16px;
		line-height:20px;
		font-family: $daisywheel;
		font-weight: 400;
		letter-spacing: 1px;	
	}
	ul {
		list-style:none;
		padding:0 10px 0 0;
		margin:0;

		li {
			position:relative;
			color: #fff;
			font-size: 12px;
			line-height:16px;
			font-family: $daisywheel;
			font-weight: 400;
			letter-spacing: 1px;
			padding:0 0 10px 0;
			text-transform: uppercase;

			&:before {
				position:absolute;
				content:'';
				top:0;
				left:-20px;
				width:15px;
				height:15px;
				background:url(../images/map-triangle.png) 50% no-repeat;
				background-size:100%;
			}					
		}
	}
	.close {
		position:absolute;
		top:10px;
		right:10px;
		width:20px;
		height:20px;
		font-size:20px;
		color:#fff;
		font-family: $daisywheel;
	}
}
.make-link {
	color:#ff4200;
	text-decoration:underline;

	&:hover {
		color:#ff4200;
		text-decoration:underline;
	}
}

.drink-loader {
	display:none;
	position:absolute;
	top:50%;
	left:50%;
	width:60px;
	height:60px;
	background:url(../images/loader.gif) 50% no-repeat;
	z-index:9999999999999;
	transform:translate(-50%, -50%);
	background-size:100%;
	opacity:.5;
}

#drink {
	position:relative;
	height:100vh;
	background:url(../images/grunge.png) top left repeat;

	.snap {
		margin-top:75px;
		position:relative;
		text-align:center;
		width:100%;
		height:calc(50vh - 37.5px);
		background-size:cover !important;

		a {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			z-index:99;
		}
		.wrap {
			position:absolute;
			top:50%;
			left:50%;
			width:100%;
			height:auto;
			z-index:9;
			transform:translate(-50%, -50%);					
		}
		p.heading {
			color: $orange;
			font-family: $daisywheel;
			font-size: 16px;
			font-weight: 700;
			letter-spacing: 1px;
			text-transform: uppercase;
		}
		h2 {
			color: #ffffff;
			font-family: $dastreets;
			font-size:50px;
			font-weight: 400;
			letter-spacing: 3px;
			line-height: 28px;
			text-transform: uppercase;
		}
		p {
			color:#fff;
			font-family: $daisywheel;
			font-size: 16px;
			font-weight: 700;
			letter-spacing: 1px;
			text-transform: uppercase;
		}
		.bg {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			z-index:1;
			opacity:.5;
			background-size:cover !important;
		}
	}
	.recommended {
		position:relative;
		text-align:center;
		width:100%;
		height:calc(50vh - 37.5px);
		background-size:cover !important;
		overflow:hidden;

		.bg-wrapper {
		    display:block;
		    position:absolute;
		    top:0;
		    left:0;
		    width:100%;
		    height:100%;
		}
		.bg {
		    display:block;
		    position:absolute;
		    top:0;
		    left:0;
		    margin:0;
		    padding:0;
		    top: 50%;
		    left: 50%;
		    min-height: 100%;
		    min-width: 100%;
		    width: auto;
		    height: auto;
		    z-index:0;
		    transform: translate(-50%,-50%);
		}
		a {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			z-index:99;
		}
		.wrap {
			position:absolute;
			top:50%;
			left:50%;
			width:100%;
			height:auto;
			z-index:9;
			transform:translate(-50%, -50%);					
		}
		p.heading {
			color: $orange;
			font-family: $daisywheel;
			font-size: 16px;
			font-weight: 700;
			letter-spacing: 1px;
			text-transform: uppercase;					
		}
		h2 {
			color: #ffffff;
			font-family: $dastreets;
			font-size:50px;
			font-weight: 400;
			letter-spacing: 3px;
			line-height: 48px;
			text-transform: uppercase;
		}
	}
	.about-slide {
		position:absolute;
		display:block;
		height: calc(100% - 65px);
		width:100%;

		.about {
			position:relative;
			z-index:999;
			width:100%;

			.white-box {
				padding:40px;

				p.title {
					font-family: $dastreets;
					color:#fff;
					font-size:36px;
					line-height:42px;
					text-align:center;
					padding-top:65px;
				}
				p.heading {
					color: $orange;
					font-family: $dastreets;
					font-size: 24px;
					font-weight: 400;
					letter-spacing: 1px;
					text-align:center;
					text-transform: uppercase;
					margin:0 0 5px 0;
					padding-top:15px;				
				}
				.meta {
					font-size:14px;
					margin:10px 0 20px 0;
					text-align:center;
				}
				p {
					color: #fff;
					font-family: $daisywheel;
					font-size: 12px;
					font-weight: 400;
					letter-spacing: 1px;
					line-height: 16px;
					padding:0;
					margin:0;
					text-transform: uppercase;						
				}
				.note {
					text-align:center;
					padding-top:20px;
				}
				a {
					color:#ff4200;
				}
			}
		}
		.overlay {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			z-index:2;
			opacity:.5;
			background:#000;
		}
		.bg-wrapper {
		    display:block;
		    position:absolute;
		    top:0;
		    left:0;
		    width:100%;
		    height:calc(100% - 75px);
		    margin-top:75px;
		}
		.bg {
		    display:block;
		    position:absolute;
		    top:0;
		    left:0;
		    margin:0;
		    padding:0;
		    top: 50%;
		    left: 50%;
		    min-height: 100%;
		    min-width: 100%;
		    width: auto;
		    height: auto;
		    z-index:0;
		    opacity:0;
		    transform: translate(-50%,-50%) scale(3);
		    transform-origin:50%;
			transition:.6s;

			&.on {
				transform:translate(-50%,-50%) scale(1);
				opacity:1;
			}
		}
	}
	.swiper-container {
		// position:absolute;
		// top:0;
		// left:0;
		z-index:-1;
		height:calc(100% - 65px);

		.swiper-slide {
			overflow:hidden;
			background:#000;

			.step {
				position:absolute;
				bottom:-100%;
				left:0;
				width:100%;
				height:auto;
				background:url(../images/grunge.png) rgba(0, 0, 0, 0.4) top left repeat;
				z-index:999;
				padding:20px;
				opacity:0;
				transition:.6s;

				&.on {
					opacity:1;
					bottom:0;
				}
				.num {
					position:absolute;
					top:0;
					left:50%;
					opacity:0;
					text-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
					color: #ff4200;
					font-family: $edo;
					font-size: 60px;
					font-weight: 400;
					letter-spacing: 2px;
					transform:translate(-50%, -50%) scale(3);
					transition:.6s;

					&.on {
						opacity:1;
						transform:translate(-50%, -50%) scale(1);
					}
				}
				ul {
					list-style:none;
					padding:0;

					li {
						color: #ffffff;
						font-size: 12px;
						line-height:16px;
						font-family: $daisywheel;
						font-weight: 400;
						letter-spacing: 1px;
						text-transform: uppercase;						
					}
				}
				p {
					position:relative;
					left:-150%;
					opacity:0;
					color: #ffffff;
					font-family: $daisywheel;
					font-size: 18px;
					font-weight: 400;
					letter-spacing: 2px;
					line-height: 20px;
					text-align:center;
					text-transform: uppercase;
					transition:.6s;

					&.on {
						opacity:1;
						left:0;
					}
				}
			}
			.overlay {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				z-index:2;
				background: -moz-linear-gradient(top,  rgba(0,0,0,1) 0%, rgba(0,0,0,0.05) 23%, rgba(0,0,0,0.01) 24%, rgba(0,0,0,0.01) 73%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.05) 23%,rgba(0,0,0,0.01) 24%,rgba(0,0,0,0.01) 73%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.05) 23%,rgba(0,0,0,0.01) 24%,rgba(0,0,0,0.01) 73%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			}
			.bg-wrapper {
			    display:block;
			    position:absolute;
			    top:0;
			    left:0;
			    width:100%;
			    height:calc(100% - 75px);
			    margin-top:75px;
			}
			.bg {
			    display:block;
			    position:absolute;
			    top:0;
			    left:0;
			    margin:0;
			    padding:0;
			    top: 50%;
			    left: 50%;
			    min-height: 100%;
			    min-width: 100%;
			    width: auto;
			    height: auto;
			    z-index:0;
			    opacity:0;
			    transform: translate(-50%,-50%) scale(3);
			    transform-origin:50%;
				transition:.6s;

				&.on {
					transform:translate(-50%,-50%) scale(1);
					opacity:1;
				}
			}			
			.ingredients {
				display:none;
				position:relative;
				z-index:999;
				width:100%;
				padding:0 60px;
				margin-top:100px;

				p {
					color: $orange;
					font-family: $dastreets;
					font-size: 24px;
					font-weight: 400;
					letter-spacing: 1px;
					text-align:center;
					margin:0 0 5px 0;
					text-transform: uppercase;						
				}
				.note {
					padding-top:20px;
					color:#fff;
					font-size:11px;
					line-height:13px;
					font-family: $daisywheel;
				}
				.extra {
					padding-top:40px;
					color: #fff;
					font-size: 16px;
					line-height:20px;
					font-family: $daisywheel;
					font-weight: 400;
					letter-spacing: 1px;	
				}
				ul {
					list-style:none;
					padding:0 10px 0 0;
					margin:0;

					li {
						position:relative;
						color: #fff;
						font-size: 12px;
						line-height:16px;
						font-family: $daisywheel;
						font-weight: 400;
						letter-spacing: 1px;
						padding:0 0 10px 0;
						text-transform: uppercase;

						&:before {
							position:absolute;
							content:'';
							top:0;
							left:-20px;
							width:15px;
							height:15px;
							background:url(../images/map-triangle.png) 50% no-repeat;
							background-size:100%;
						}					
					}
				}				
			}
		}
		.swiper-slide:first-child .overlay {
			background:#000;
			opacity:.5;
		}
		.swiper-slide:last-child .bg-wrapper .bg {
			top:30%;
		}
	}
	.prev-start {
		display:none;
	}
	.prev,
	.prev-start {
		position:absolute;
		top:40%;
		left:0;
		width:46px;
		height:72px;
		z-index:99;
		background:url(../images/back_bg.svg) 50% no-repeat;

		.overlay {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			z-index:9;
			background:url(../images/grunge.png) top left no-repeat;
			background-size:100%;
		}
		.arrow {
			display:block;
			position:absolute;
			top:20px;
			left:10px;
			width:20px;
			height:20px;
			color: #ffffff;
			font-family: $daisywheel;
			font-size: 8px;
			line-height:60px;
			font-weight: 400;
			text-transform: uppercase;
			background:url(../images/back.svg) 50% no-repeat;
		}
	}
	.next, 
	.next-end,
	.next-start {
		position:absolute;
		top:40%;
		right:0;
		width:46px;
		height:72px;
		z-index:9999;
		background:url(../images/next_bg.svg) 50% no-repeat;

		.overlay {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			z-index:9;
			background:url(../images/grunge.png) top left no-repeat;
			background-size:100%;
		}
		.arrow {
			display:block;
			position:absolute;
			top:20px;
			left:15px;
			width:20px;
			height:20px;
			color: #ffffff;
			font-family: $daisywheel;
			font-size: 8px;
			line-height:60px;
			font-weight: 400;
			text-transform: uppercase;
			background:url(../images/next.svg) 50% no-repeat;
		}
	}
	.progress {
		position:absolute;
		bottom:45px;
		z-index:999;
		width:100%;
		height:20px;
		background-color: #4a4a4a;
		box-shadow: inset 0 4px 20px rgba(0, 0, 0, 0.5);

		.overlay {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			background: url(../images/grunge.png) 0 0 repeat;
			background-size:50%;
			z-index:9;
		}
		.fill {
			display:block;
			position:absolute;
			top:0;
			left:0;
			z-index:1;
			width:0;
			height:100%;
			background-color: #ff4200;
			box-shadow: inset 0 4px 20px rgba(0, 0, 0, 0.5);
			transition:.6s;
		}
		.num {
			font-size: 10px;
			font-family:$gillreg;
			color:#fff;
			position:absolute;
			top:50%;
			right:20px;
			transform:translate(0, -50%);
		}
	}
	.mobile-bar {
		position:absolute;
		bottom:0;
		z-index:999;
		width:100%;
		height:45px;
		box-shadow: 0 -3px 4px rgba(0, 0, 0, 0.2);
		background-color: #1c1c1c;

		a {
			display:block;
			position:relative;
			float:left;
			width:33.3333%;
			height:45px;
			color: #ffffff;
			font-family: $daisywheel;
			font-size: 11px;
			line-height:45px;
			font-weight: 400;
			letter-spacing: 1px;
			padding:0 10px;
			text-transform: uppercase;
			text-align:center;

			span {
				display:inline-block;
				vertical-align:middle;
				padding-left:5px;
				text-align:left;
			}
			&.bookmark-btn {

				&:after {
					position:absolute;
					content:'';
					height:100%;
					width:1px;
					background:#444;
					top:0;
					right:0;
				}
			}
			&.share-btn {

				&:after {
					position:absolute;
					content:'';
					height:100%;
					width:1px;
					background:#444;
					top:0;
					right:0;
				}
			}
		}
		i {
			margin-right:5px;
			display:inline-block;
			vertical-align:middle;
		}
	}
}

#share-box {
	display:none;
	position:absolute;
	top:50%;
	left:50%;
	z-index:99999999999;
	box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
	background-color: #1c1c1c;
	width:92%;
	max-width:650px;
	height:auto;
	transform:translate(-50%, -50%);

	h2 {
		color: #ff4200;
		font-family: $daisywheel;
		font-size: 14px;
		font-weight: 400;
		letter-spacing: 1px;
		text-transform: uppercase;	
		text-align:center;
		padding:10px 0;
		margin:0;
		border-bottom:1px solid #4a4a4a;
	}
	#share {

		.a2a_kit {
			font-size:0;
			text-align:center;

			.a2a_dd {
				display:none !important;
			}
			a {
				display:inline-block;
				width:20%;
				height:50px;

				span {
					display:none !important;
				}
			}
			.a2a_button_facebook {
				background:url(../images/share-facebook.svg) 50% no-repeat;
				border-right:1px solid #4a4a4a;
			}
			.a2a_button_twitter {
				background:url(../images/share-twitter.svg) 50% no-repeat;
				border-right:1px solid #4a4a4a;
			}
			.a2a_button_sms {
				background:url(../images/share-chat.png) 50% no-repeat;
				border-right:1px solid #4a4a4a;
			}
			.a2a_button_email {
				background:url(../images/share-mail.png) 50% no-repeat;
			}
			.instagram {
				background:url(../images/share-instagram.svg) 50% no-repeat;
				border-right:1px solid #4a4a4a;
			}
			.a2a_button_pinterest {
				background:url(../images/share-pinterest.svg) 50% no-repeat;
				border-right:1px solid #4a4a4a;
				background-size:25px 25px;
			}
		}
		.close {
			display:inline-block;
			width:100%;
			color: #fff;
			font-family: $daisywheel;
			font-size: 14px;
			font-weight: 400;
			letter-spacing: 1px;
			text-transform: uppercase;	
			text-align:center;
			padding:10px 0;
			margin:0;
			border-top:1px solid #4a4a4a;
		}
	}
}

#voice-box {

	h2 {
		font-size:36px !important;
		line-height:36px !important;
	}
	p {
		border-bottom:none !important;
		color:#fff !important;
	}
	.close-x {
		position:absolute !important;
		top:5px !important;
		bottom:inherit !important;
		right:5px !important;
		width:20px !important;
		height:20px !important;
		background:url(../images/close.svg) 50% no-repeat;
		background-size:100%;
		z-index:9999;
	}
}

#experience {
	display:none;
	position:absolute;
	top:50%;
	left:50%;
	z-index:99999999999;
	box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
	background-color: #1c1c1c;
	width:92%;
	max-width:650px;
	height:auto;
	transform:translate(-50%, -50%);

	p {
		color: #ff4200;
		width:100%;
		font-family: $daisywheel;
		font-size: 14px;
		line-height:18px;
		font-weight: 400;
		letter-spacing: 1px;
		text-transform: uppercase;	
		text-align:center;
		padding:10px 20px;
		margin:10px 0 0 0;
	}
	.close {
		position:absolute !important;
		top:5px !important;
		bottom:inherit !important;
		right:5px !important;
		width:20px !important;
		height:20px !important;
		background:url(../images/close.svg) 50% no-repeat;
		background-size:100%;
		z-index:9999;
	}
}

#like-box, #voice-box {
	display:none;
	position:absolute;
	top:50%;
	left:50%;
	z-index:99999999999;
	box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
	background-color: #1c1c1c;
	width:92%;
	max-width:650px;
	height:auto;
	transform:translate(-50%, -50%);

	h2 {
		width:100%;
		font-family:$dastreets;
		font-size:46px;
		line-height:42px;
		color:#fff;
		letter-spacing:2px;
		text-align:center;
		padding:20px 0 0 0;
	}
	p {
		color: #ff4200;
		width:100%;
		font-family: $daisywheel;
		font-size: 14px;
		line-height:18px;
		font-weight: 400;
		letter-spacing: 1px;
		text-transform: uppercase;	
		text-align:center;
		padding:10px 20px;
		margin:0;
		border-bottom:1px solid #4a4a4a;

		a {
			color: #fff;
			text-decoration:underline;
		}
	}
	.fine {
		color:#ccc;
		font-size:9px;
		line-height:14px;
	}
	.stroke {
		display:block;
		margin:0 auto;
		width:100%;
	    width: 177px;
	    height: 58px;
	    color: #ff4200;
	    font-family: $daisywheel;
	    font-size: 42px;
	    line-height: 60px;
	    font-weight: 700;
	    letter-spacing: 1px;
	    text-align:center;
	    text-transform: uppercase;		
	}
	.a2a_kit {
		font-size:0;
		text-align:center;

		.a2a_dd {
			display:none !important;
		}
		a {
			display:inline-block;
			width:100%;
			height:50px;

			span {
				display:none !important;
			}
		}
		.instagram {
			background:url(../images/share-instagram.svg) 50% no-repeat;
		}
	}
	.close {
		display:inline-block;
		width:100%;
		color: #fff;
		font-family: $daisywheel;
		font-size: 14px;
		font-weight: 400;
		letter-spacing: 1px;
		text-transform: uppercase;	
		text-align:center;
		padding:10px 0;
		margin:0;
		border-top:1px solid #4a4a4a;
	}
}


.page-template-template-lemon-tell-you-somethin-honey-complete #drink-bg12 {
	top:30% !important;
}

.page-template-template-scotch-enlightenment-complete #drink-bg7 {
	top:30% !important;
}

.page-template-template-au-kee-ta-complete #drink-bg17 {
	top:30% !important;
}

.page-template-template-risky-colada-complete #drink-bg10 {
	top:30% !important;
}

.page-template-template-sage-against-the-machine-complete #drink-bg8 {
	top:30% !important;
}

.page-template-template-the-dancy-nancy #step11 {
	top:50% !important;
}

.page-template-template-the-viper-of-kilpatrick #step10 {
	top:50% !important;
}