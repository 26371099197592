//
// Name:            Fonts
//
// Description:     Contains all fonts
//


// ========================================================================
// Component: Fonts
// ========================================================================

@import url("//hello.myfonts.net/count/33912c");

@font-face {
    font-family: 'GillSansMTStd-Light';
    src: url('../fonts/33912C_0_0.eot');
    src: url('../fonts/33912C_0_0.eot?#iefix') format('embedded-opentype'),
         url('../fonts/33912C_0_0.woff2') format('woff2'),
         url('../fonts/33912C_0_0.woff') format('woff'),
         url('../fonts/33912C_0_0.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'daisywheelregular';
    src: url('../fonts/daisywhl-webfont.woff2') format('woff2'),
         url('../fonts/daisywhl-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'da_streetsregular';
    src: url('../fonts/da_streets-webfont.woff2') format('woff2'),
         url('../fonts/da_streets-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GillSansMT';
    src: url('../fonts/GillSansMT.eot?#iefix') format('embedded-opentype'), 
        url('../fonts/GillSansMT.woff') format('woff'),
        url('../fonts/GillSansMT.ttf')  format('truetype'),
        url('../fonts/GillSansMT.svg#GillSansMT') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'edoregular';
    src: url('../fonts/edo-webfont.woff2') format('woff2'),
         url('../fonts/edo-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNextMedium';
    src: url('../fonts/AvenirNextMedium.eot');
    src: url('../fonts/AvenirNextMedium.eot') format('embedded-opentype'),
         url('../fonts/AvenirNextMedium.woff2') format('woff2'),
         url('../fonts/AvenirNextMedium.woff') format('woff'),
         url('../fonts/AvenirNextMedium.ttf') format('truetype'),
         url('../fonts/AvenirNextMedium.svg#AvenirNextMedium') format('svg');
}

@font-face {
    font-family: 'AvenirNextRegular';
    src: url('../fonts/AvenirNextRegular.eot');
    src: url('../fonts/AvenirNextRegular.eot') format('embedded-opentype'),
         url('../fonts/AvenirNextRegular.woff2') format('woff2'),
         url('../fonts/AvenirNextRegular.woff') format('woff'),
         url('../fonts/AvenirNextRegular.ttf') format('truetype'),
         url('../fonts/AvenirNextRegular.svg#AvenirNextRegular') format('svg');
}

@font-face {
    font-family: 'AvenirNextBold';
    src: url('../fonts/AvenirNextBold.eot');
    src: url('../fonts/AvenirNextBold.eot') format('embedded-opentype'),
         url('../fonts/AvenirNextBold.woff2') format('woff2'),
         url('../fonts/AvenirNextBold.woff') format('woff'),
         url('../fonts/AvenirNextBold.ttf') format('truetype'),
         url('../fonts/AvenirNextBold.svg#AvenirNextBold') format('svg');
}

@font-face {
    font-family: 'font-awesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/fontawesome-webfont.woff') format('woff'),
         url('../fonts/fontawesome-webfont.ttf') format('truetype'),
         url('../fonts/fontawesome-webfont.svg#fontawesome-webfont') format('svg');
    font-weight: normal;
    font-style: normal;
}